.rdx {
  .btn {
    @include BasicGothicProBold;
    border-radius: 100px;
    box-sizing: border-box;
    color: $light;
    display: inline-block;
    font-size: 10px;
    margin: 10px 0;
    padding: 15px 25px;
    text-decoration: none;
    text-align: center;
    transition: all .35s ease-out;

    @media (min-width: 768px) {
      font-size: 18px;
      margin: 50px 20px;
      padding: 25px 40px;
    }
    
    @media (min-width: 1024px) {
      font-size: 18px;
      margin: 50px 0;
      padding: 20px 30px;
    }
    
    @media (min-width: 1440px) {
      font-size: 18px;
      margin: 50px 0;
      padding: 20px 35px;
    }
    
    &.-line {
      box-shadow: inset 0 0 0 3px $light;

      &:hover {
        background: $light-base;
        cursor: pointer;
        color: $light;
      }
    }
    
    &.-filled {
      background: transparent;
      box-shadow: inset 0 0 0 3px $blue;
      color: $light;

      &:hover {
        background: $light-base;
        box-shadow: inset 0 0 0 3px $light-base;
        cursor: pointer;
        color: $light;
      }
    }

    &.-dark {
      box-shadow: inset 0 0 0 3px $blue;
      color: $light;

      &:hover {
        background: $light-base;
        cursor: pointer;
        color: $light;
        box-shadow: inset 0 0 0 3px $light-base;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
}