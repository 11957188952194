@mixin BasicGothicProBlack {
  font-family: ff-basic-gothic-pro, sans-serif;
  font-weight: 900;
  font-style: normal;
}

@mixin BasicGothicProBlackItalic {
  font-family: ff-basic-gothic-pro, sans-serif;
  font-weight: 900;
  font-style: italic;
}

@mixin BasicGothicProBold {
  font-family: ff-basic-gothic-pro, sans-serif;
  font-weight: 700;
  font-style: normal;
}

@mixin BasicGothicProBoldItalic {
  font-family: ff-basic-gothic-pro, sans-serif;
  font-weight: 700;
  font-style: italic;
}

@mixin BasicGothicProBook {
  font-family: ff-basic-gothic-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin BasicGothicProBookItalic {
  font-family: ff-basic-gothic-pro, sans-serif;
  font-weight: 400;
  font-style: italic;
}

@mixin BasicGothicProDemibold {
  font-family: ff-basic-gothic-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
}

@mixin BasicGothicProDemiboldItalic {
  font-family: ff-basic-gothic-pro, sans-serif;
  font-weight: 600;
  font-style: italic;
}

@mixin BasicGothicProExtralight {
  font-family: ff-basic-gothic-pro, sans-serif;
  font-weight: 100;
  font-style: normal;
}

@mixin BasicGothicProExtralightItalic {
  font-family: ff-basic-gothic-pro, sans-serif;
  font-weight: 100;
  font-style: italic;
}

@mixin BasicGothicProItalic {
  font-family: ff-basic-gothic-pro, sans-serif;
  font-weight: 300;
  font-style: italic;
}

@mixin BasicGothicProLight {
  font-family: ff-basic-gothic-pro, sans-serif;
  font-weight: 200;
  font-style: normal;  
}

@mixin BasicGothicProLightItalic {
  font-family: ff-basic-gothic-pro, sans-serif;
  font-weight: 200;
  font-style: italic;
}

@mixin BasicGothicProMedium {
  font-family: ff-basic-gothic-pro, sans-serif;
  font-weight: 500;
  font-style: normal;
}

@mixin BasicGothicProMediumItalic {
  font-family: ff-basic-gothic-pro, sans-serif;
  font-weight: 500;
  font-style: italic;
}

@mixin BasicGothicProRegular {
  font-family: ff-basic-gothic-pro, sans-serif;
  font-weight: 300;
  font-style: normal;
}


