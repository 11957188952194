.rdx .btn {
  font-family: ff-basic-gothic-pro, sans-serif;
  font-weight: 700;
  font-style: normal;
  border-radius: 100px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: #fff;
  display: inline-block;
  font-size: 10px;
  margin: 10px 0;
  padding: 15px 25px;
  text-decoration: none;
  text-align: center;
  -webkit-transition: all .35s ease-out;
  transition: all .35s ease-out; }
  @media (min-width: 768px) {
    .rdx .btn {
      font-size: 18px;
      margin: 50px 20px;
      padding: 25px 40px; } }
  @media (min-width: 1024px) {
    .rdx .btn {
      font-size: 18px;
      margin: 50px 0;
      padding: 20px 30px; } }
  @media (min-width: 1440px) {
    .rdx .btn {
      font-size: 18px;
      margin: 50px 0;
      padding: 20px 35px; } }
  .rdx .btn.-line {
    -webkit-box-shadow: inset 0 0 0 3px #fff;
            box-shadow: inset 0 0 0 3px #fff; }
    .rdx .btn.-line:hover {
      background: #008efc;
      cursor: pointer;
      color: #fff; }
  .rdx .btn.-filled {
    background: transparent;
    -webkit-box-shadow: inset 0 0 0 3px #008efc;
            box-shadow: inset 0 0 0 3px #008efc;
    color: #fff; }
    .rdx .btn.-filled:hover {
      background: #008efc;
      -webkit-box-shadow: inset 0 0 0 3px #008efc;
              box-shadow: inset 0 0 0 3px #008efc;
      cursor: pointer;
      color: #fff; }
  .rdx .btn.-dark {
    -webkit-box-shadow: inset 0 0 0 3px #008efc;
            box-shadow: inset 0 0 0 3px #008efc;
    color: #fff; }
    .rdx .btn.-dark:hover {
      background: #008efc;
      cursor: pointer;
      color: #fff;
      -webkit-box-shadow: inset 0 0 0 3px #008efc;
              box-shadow: inset 0 0 0 3px #008efc; }
  .rdx .btn:hover {
    cursor: pointer; }

* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; }

.rdx-banner--full.-performance {
  background: url(../images/performance-experts-banner-seq-3.jpg) top center no-repeat;
  background-size: cover;
  height: 60vh; }
  @media (min-width: 1024px) {
    .rdx-banner--full.-performance {
      height: 75vh; } }
  @media (min-width: 1440px) {
    .rdx-banner--full.-performance {
      height: 90vh; } }
  .rdx-banner--full.-performance .rdx-banner--full_copy {
    margin: auto;
    padding-left: 0;
    text-align: center;
    z-index: 1; }
    @media (min-width: 375px) {
      .rdx-banner--full.-performance .rdx-banner--full_copy {
        width: 85%; } }
    @media (min-width: 1024px) {
      .rdx-banner--full.-performance .rdx-banner--full_copy {
        width: 60%; } }
    @media (min-width: 1440px) {
      .rdx-banner--full.-performance .rdx-banner--full_copy {
        width: 60%; } }
    @media (min-width: 1920px) {
      .rdx-banner--full.-performance .rdx-banner--full_copy {
        width: 45%; } }
  .rdx-banner--full.-performance .rdx-banner--text {
    line-height: 1;
    width: 100%; }
    @media (min-width: 375px) {
      .rdx-banner--full.-performance .rdx-banner--text {
        font-size: 38px; } }
    @media (min-width: 400px) {
      .rdx-banner--full.-performance .rdx-banner--text {
        font-size: 42px; } }
    @media (min-width: 768px) {
      .rdx-banner--full.-performance .rdx-banner--text {
        font-size: 72px; } }
    @media (min-width: 1440px) {
      .rdx-banner--full.-performance .rdx-banner--text {
        font-size: 100px; } }
    @media (min-width: 1920px) {
      .rdx-banner--full.-performance .rdx-banner--text {
        font-size: 130px; } }
  .rdx-banner--full.-performance .rdx-banner--subtext {
    display: block;
    line-height: 1.25;
    width: 85%; }
    @media (min-width: 375px) {
      .rdx-banner--full.-performance .rdx-banner--subtext {
        font-size: 12px;
        line-height: 1.5; } }
    @media (min-width: 768px) {
      .rdx-banner--full.-performance .rdx-banner--subtext {
        font-size: 20px;
        line-height: 1.5; } }
    @media (min-width: 1024px) {
      .rdx-banner--full.-performance .rdx-banner--subtext {
        font-size: 16px;
        margin: 0;
        width: 60%; } }
    @media (min-width: 1920px) {
      .rdx-banner--full.-performance .rdx-banner--subtext {
        font-size: 18px;
        width: 70%; } }
  @media (min-width: 1920px) {
    .rdx-banner--full.-performance {
      height: 100vh; } }

.rdx-banner--full.-functional {
  background: url(../images/functional-training-banner.jpg) top center no-repeat;
  background-size: cover;
  height: 60vh; }
  @media (min-width: 1024px) {
    .rdx-banner--full.-functional {
      height: 75vh; } }
  @media (min-width: 1440px) {
    .rdx-banner--full.-functional {
      height: 90vh; } }
  .rdx-banner--full.-functional .rdx-banner--full_copy {
    margin: auto;
    padding-left: 0;
    text-align: center;
    z-index: 1; }
    @media (min-width: 375px) {
      .rdx-banner--full.-functional .rdx-banner--full_copy {
        width: 85%; } }
    @media (min-width: 1024px) {
      .rdx-banner--full.-functional .rdx-banner--full_copy {
        width: 60%; } }
    @media (min-width: 1440px) {
      .rdx-banner--full.-functional .rdx-banner--full_copy {
        width: 65%; } }
    @media (min-width: 1920px) {
      .rdx-banner--full.-functional .rdx-banner--full_copy {
        width: 50%; } }
  .rdx-banner--full.-functional .rdx-banner--text {
    line-height: 1;
    width: 100%; }
    @media (min-width: 375px) {
      .rdx-banner--full.-functional .rdx-banner--text {
        font-size: 38px; } }
    @media (min-width: 400px) {
      .rdx-banner--full.-functional .rdx-banner--text {
        font-size: 42px; } }
    @media (min-width: 768px) {
      .rdx-banner--full.-functional .rdx-banner--text {
        font-size: 72px; } }
    @media (min-width: 1440px) {
      .rdx-banner--full.-functional .rdx-banner--text {
        font-size: 100px; } }
    @media (min-width: 1920px) {
      .rdx-banner--full.-functional .rdx-banner--text {
        font-size: 130px; } }
  .rdx-banner--full.-functional .rdx-banner--subtext {
    display: block;
    line-height: 1.25;
    width: 85%; }
    @media (min-width: 375px) {
      .rdx-banner--full.-functional .rdx-banner--subtext {
        font-size: 12px;
        line-height: 1.5; } }
    @media (min-width: 768px) {
      .rdx-banner--full.-functional .rdx-banner--subtext {
        font-size: 20px;
        line-height: 1.5; } }
    @media (min-width: 1024px) {
      .rdx-banner--full.-functional .rdx-banner--subtext {
        font-size: 16px;
        margin: 0;
        width: 60%; } }
    @media (min-width: 1920px) {
      .rdx-banner--full.-functional .rdx-banner--subtext {
        font-size: 18px;
        width: 70%; } }
  @media (min-width: 1920px) {
    .rdx-banner--full.-functional {
      height: 100vh; } }

.rdx-banner--full.-fitness {
  background: url(../images/science-fitness-lab-banner-seq1.jpg) top center no-repeat;
  background-size: cover;
  height: 60vh; }
  @media (min-width: 1024px) {
    .rdx-banner--full.-fitness {
      height: 75vh; } }
  @media (min-width: 1440px) {
    .rdx-banner--full.-fitness {
      height: 90vh; } }
  @media (min-width: 1920px) {
    .rdx-banner--full.-fitness {
      height: 100vh; } }
  .rdx-banner--full.-fitness .rdx-banner--full_copy {
    margin: auto;
    padding-left: 0;
    text-align: center;
    z-index: 1; }
    @media (min-width: 375px) {
      .rdx-banner--full.-fitness .rdx-banner--full_copy {
        padding: 120px 25px 0;
        width: 85%; } }
    @media (min-width: 375px) and (min-height: 812px) {
      .rdx-banner--full.-fitness .rdx-banner--full_copy {
        padding: 140px 25px 0; } }
    @media (min-width: 768px) {
      .rdx-banner--full.-fitness .rdx-banner--full_copy {
        padding: 180px 25px 0; } }
    @media (min-width: 1024px) {
      .rdx-banner--full.-fitness .rdx-banner--full_copy {
        width: 60%; } }
    @media (min-width: 1280px) {
      .rdx-banner--full.-fitness .rdx-banner--full_copy {
        padding: 210px 25px 0; } }
    @media (min-width: 1440px) {
      .rdx-banner--full.-fitness .rdx-banner--full_copy {
        width: 55%; } }
    @media (min-width: 1920px) {
      .rdx-banner--full.-fitness .rdx-banner--full_copy {
        width: 45%; } }
  .rdx-banner--full.-fitness .rdx-banner--text {
    line-height: 1;
    width: 100%; }
    @media (min-width: 375px) {
      .rdx-banner--full.-fitness .rdx-banner--text {
        font-size: 38px; } }
    @media (min-width: 400px) {
      .rdx-banner--full.-fitness .rdx-banner--text {
        font-size: 42px; } }
    @media (min-width: 768px) {
      .rdx-banner--full.-fitness .rdx-banner--text {
        font-size: 72px; } }
    @media (min-width: 1440px) {
      .rdx-banner--full.-fitness .rdx-banner--text {
        font-size: 100px; } }
    @media (min-width: 1920px) {
      .rdx-banner--full.-fitness .rdx-banner--text {
        font-size: 130px; } }
  .rdx-banner--full.-fitness .rdx-banner--subtext {
    display: block;
    line-height: 1.25;
    width: 85%; }
    @media (min-width: 375px) {
      .rdx-banner--full.-fitness .rdx-banner--subtext {
        font-size: 12px;
        line-height: 1.5; } }
    @media (min-width: 768px) {
      .rdx-banner--full.-fitness .rdx-banner--subtext {
        font-size: 20px;
        line-height: 1.5; } }
    @media (min-width: 1024px) {
      .rdx-banner--full.-fitness .rdx-banner--subtext {
        font-size: 16px;
        margin: 0;
        width: 60%; } }
    @media (min-width: 1920px) {
      .rdx-banner--full.-fitness .rdx-banner--subtext {
        font-size: 18px;
        width: 70%; } }
  @media (min-width: 1920px) {
    .rdx-banner--full.-fitness {
      height: 100vh; } }

html {
  scroll-behavior: smooth; }

.rdx {
  overflow-x: hidden; }
  .rdx-telephone {
    font-size: 12px;
    left: 50%;
    position: absolute;
    top: 25px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    z-index: 10; }
    @media (min-width: 375px) {
      .rdx-telephone {
        font-size: 14px; } }
    @media (min-width: 768px) {
      .rdx-telephone {
        font-size: 18px;
        top: 35px; } }
    @media (min-width: 1024px) {
      .rdx-telephone {
        font-size: 16px;
        left: 80%;
        top: 30px; } }
    @media (min-width: 1280px) {
      .rdx-telephone {
        font-size: 16px;
        left: 80%;
        top: 63px; } }
    @media (min-width: 1440px) {
      .rdx-telephone {
        font-size: 16px;
        left: 82%;
        top: 63px; } }
    @media (min-width: 1920px) {
      .rdx-telephone {
        font-size: 16px;
        left: 85%; } }
    @media (min-width: 2560px) {
      .rdx-telephone {
        font-size: 16px;
        left: 90%; } }
    .rdx-telephone--contact {
      font-family: ff-basic-gothic-pro, sans-serif;
      font-weight: 400;
      font-style: normal;
      color: #fff;
      margin-right: 5px; }
    .rdx-telephone--number {
      font-family: ff-basic-gothic-pro, sans-serif;
      font-weight: 700;
      font-style: normal;
      color: #008efc;
      cursor: pointer;
      text-decoration: none; }
      .rdx-telephone--number:hover {
        text-decoration: underline; }
  .rdx-banner--text {
    font-family: ff-basic-gothic-pro, sans-serif;
    font-weight: 900;
    font-style: italic;
    color: #fff;
    font-size: 34px;
    margin: 0;
    text-transform: uppercase;
    z-index: 9; }
    @media (min-width: 768px) {
      .rdx-banner--text {
        font-size: 55px; } }
    @media (min-width: 1024px) {
      .rdx-banner--text {
        font-size: 64px;
        line-height: 1; } }
    @media (min-width: 1440px) {
      .rdx-banner--text {
        font-size: 90px; } }
    @media (min-width: 1920px) {
      .rdx-banner--text {
        font-size: 135px; } }
  .rdx-banner--subtext {
    font-family: ff-basic-gothic-pro, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #fff;
    display: none;
    font-size: 10px;
    width: 80%;
    z-index: 9; }
    .rdx-banner--subtext.-thank-you {
      display: block; }
    @media (min-width: 1024px) {
      .rdx-banner--subtext {
        display: block;
        font-size: 14px;
        line-height: 1.5;
        width: 60%; } }
    @media (min-width: 1440px) {
      .rdx-banner--subtext {
        font-size: 18px;
        line-height: 1.5;
        width: 60%; } }
    @media (min-width: 1920px) {
      .rdx-banner--subtext {
        font-size: 18px;
        line-height: 1.55; } }
    @media (min-width: 1920px) {
      .rdx-banner--subtext {
        font-size: 18px;
        line-height: 1.55;
        width: 42%; } }
    @media (min-width: 2560px) {
      .rdx-banner--subtext {
        font-size: 18px;
        line-height: 1.55;
        width: 45%; } }
  .rdx-banner--scroll {
    font-family: ff-basic-gothic-pro, sans-serif;
    font-weight: 600;
    font-style: normal;
    bottom: 0;
    height: 100px;
    left: 50%;
    position: absolute;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 120px;
    z-index: 1; }
    @media (min-width: 1024px) {
      .rdx-banner--scroll {
        bottom: 20px; } }
    .rdx-banner--scroll_text {
      color: #fff;
      font-size: 10px;
      left: 50%;
      position: absolute;
      top: 15%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
    .rdx-banner--scroll_icon {
      fill: #fff;
      height: 25px;
      left: 50%;
      position: absolute;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      width: 25px; }
  .rdx-banner--full {
    overflow: hidden;
    position: relative;
    height: 50vh;
    width: 100vw; }
    .rdx-banner--full.rdx-banner--full_copy {
      position: relative; }
    @media (min-width: 1024px) {
      .rdx-banner--full {
        height: 100vh; } }
    .rdx-banner--full.-approach {
      background: url(../images/method-banner-static-bg.jpg) top center no-repeat;
      background-size: cover;
      height: 70vh; }
      @media (min-width: 1024px) {
        .rdx-banner--full.-approach {
          height: 74vh; } }
      @media (min-width: 1440px) {
        .rdx-banner--full.-approach {
          height: 90vh; } }
      @media (min-width: 1920px) {
        .rdx-banner--full.-approach {
          height: 83vh; } }
      @media (min-width: 2560px) {
        .rdx-banner--full.-approach {
          height: 100vh; } }
      .rdx-banner--full.-approach .rdx-banner--full_copy {
        left: 50%;
        padding: 0;
        position: absolute;
        text-align: center;
        top: 40%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
        width: 80%;
        z-index: 1; }
        @media (min-width: 1024px) {
          .rdx-banner--full.-approach .rdx-banner--full_copy {
            width: 70%; } }
        @media (min-width: 1440px) {
          .rdx-banner--full.-approach .rdx-banner--full_copy {
            width: 50%; } }
        @media (min-width: 1920px) {
          .rdx-banner--full.-approach .rdx-banner--full_copy {
            width: 50%; } }
        @media (min-width: 2560px) {
          .rdx-banner--full.-approach .rdx-banner--full_copy {
            width: 50%; } }
      .rdx-banner--full.-approach .rdx-banner--subtext {
        display: block;
        font-size: 12px;
        line-height: 1.5;
        width: 100%; }
        @media (min-width: 768px) {
          .rdx-banner--full.-approach .rdx-banner--subtext {
            font-size: 20px;
            line-height: 1.5; } }
        @media (min-width: 1024px) {
          .rdx-banner--full.-approach .rdx-banner--subtext {
            font-size: 28px;
            margin: 0; } }
        @media (min-width: 1920px) {
          .rdx-banner--full.-approach .rdx-banner--subtext {
            font-size: 48px; } }
    .rdx-banner--full.-contact {
      background: #000523;
      background: -webkit-gradient(linear, left bottom, left top, from(#000523), to(#000523));
      background: linear-gradient(to top, #000523 0%, #000523 100%);
      height: auto; }
      .rdx-banner--full.-contact.-thank-you {
        height: 40vh; }
      @media (min-width: 375px) and (min-height: 812px) {
        .rdx-banner--full.-contact {
          height: 80vh; }
          .rdx-banner--full.-contact.-thank-you {
            height: 35vh; } }
      @media (min-width: 768px) {
        .rdx-banner--full.-contact {
          height: 100vh; }
          .rdx-banner--full.-contact.-thank-you {
            height: 35vh; } }
      @media (min-width: 1024px) {
        .rdx-banner--full.-contact {
          height: 75vh; }
          .rdx-banner--full.-contact.-thank-you {
            height: 50vh; } }
      @media (min-width: 1440px) {
        .rdx-banner--full.-contact {
          height: 100vh; }
          .rdx-banner--full.-contact.-thank-you {
            height: 60vh; } }
      @media (min-width: 1920px) {
        .rdx-banner--full.-contact.-thank-you {
          height: 70vh; } }
      .rdx-banner--full.-contact .rdx-banner--text {
        color: #008efc; }
    .rdx-banner--full_copy {
      padding: 80px 0 0 25px;
      position: relative;
      width: 90%; }
      .rdx-banner--full_copy.-thank-you {
        padding: 100px 0 0 25px; }
      @media (min-width: 768px) {
        .rdx-banner--full_copy {
          padding: 150px 0 0 70px;
          width: 100%; }
          .rdx-banner--full_copy.-thank-you {
            padding: 150px 0 0 40px;
            width: 80%; } }
      @media (min-width: 1024px) {
        .rdx-banner--full_copy {
          width: 60%; }
          .rdx-banner--full_copy.-thank-you {
            padding: 200px 0 0 50px;
            width: 80%; } }
      @media (min-width: 1440px) {
        .rdx-banner--full_copy {
          padding: 200px 0 0 70px;
          width: 60%; } }
      @media (min-width: 1920px) {
        .rdx-banner--full_copy {
          padding: 225px 0 0 150px;
          width: 60%; }
          .rdx-banner--full_copy.-thank-you {
            padding: 300px 0 0 150px; } }
      @media (min-width: 2560px) {
        .rdx-banner--full_copy {
          padding: 300px 0 0 150px;
          width: 50%; } }
  .rdx-banner--video {
    overflow: hidden;
    position: relative;
    width: 100vw; }
    @media (min-width: 768px) {
      .rdx-banner--video {
        max-height: 430px; } }
    @media (min-width: 768px) {
      .rdx-banner--video {
        max-height: 575px; } }
    @media (min-width: 1440px) {
      .rdx-banner--video {
        max-height: 810px; } }
    @media (min-width: 1920px) {
      .rdx-banner--video {
        max-height: 100vh; } }
  .rdx-pillars--heading {
    background: #000;
    margin-top: 100px;
    opacity: 0;
    -webkit-transition: margin-top 1s, opacity 2s;
    transition: margin-top 1s, opacity 2s; }
    .rdx-pillars--heading.fade-in {
      margin-top: 0;
      opacity: 1; }
    .rdx-pillars--heading_wrapper {
      font-family: ff-basic-gothic-pro, sans-serif;
      font-weight: 600;
      font-style: normal;
      border: thin solid #000;
      outline: thin solid #000;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      color: #fff;
      font-size: 24px;
      margin: 0 auto;
      padding: 20px 0;
      text-align: center;
      width: 70%; }
      @media (min-width: 375px) {
        .rdx-pillars--heading_wrapper {
          width: 60%; } }
      @media (min-width: 414px) {
        .rdx-pillars--heading_wrapper {
          width: 50%; } }
      @media (min-width: 768px) {
        .rdx-pillars--heading_wrapper {
          font-size: 34px;
          padding: 30px 0;
          width: 40%; } }
      @media (min-width: 1024px) {
        .rdx-pillars--heading_wrapper {
          width: 30%; } }
      @media (min-width: 1280px) {
        .rdx-pillars--heading_wrapper {
          width: 25%; } }
      @media (min-width: 1440px) {
        .rdx-pillars--heading_wrapper {
          width: 20%; } }
      @media (min-width: 1920px) {
        .rdx-pillars--heading_wrapper {
          font-size: 48px;
          width: 21%; } }
      @media (min-width: 2560px) {
        .rdx-pillars--heading_wrapper {
          font-size: 48px;
          margin: auto;
          padding: 50px 0;
          width: 18%; } }
  .rdx-pillars--wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
  .rdx-pillars--image {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    height: 30vh;
    position: relative;
    -webkit-transition: all .25s ease-out;
    transition: all .25s ease-out; }
    @media (min-width: 1024px) {
      .rdx-pillars--image {
        height: 70vh; } }
    .rdx-pillars--image:hover {
      -webkit-filter: grayscale(0);
              filter: grayscale(0);
      cursor: pointer;
      -webkit-box-shadow: inset 0 2px #008efc;
              box-shadow: inset 0 2px #008efc; }
    .rdx-pillars--image.-balance {
      background: url(../images/method-nav-scroll-balance-seq1.jpg) top center no-repeat;
      background-size: cover;
      margin-top: 100px;
      opacity: 0;
      -webkit-transition: margin-top 1s, opacity 2s;
      transition: margin-top 1s, opacity 2s; }
      .rdx-pillars--image.-balance.fade-in {
        margin-top: 0;
        opacity: 1; }
    .rdx-pillars--image.-recovery {
      background: url(../images/method-nav-scroll-recovery-seq1.jpg) top center no-repeat;
      background-size: cover;
      margin-top: 100px;
      opacity: 0;
      -webkit-transition: margin-top 1s .25s, opacity 2s .25s;
      transition: margin-top 1s .25s, opacity 2s .25s; }
      .rdx-pillars--image.-recovery.fade-in {
        margin-top: 0;
        opacity: 1; }
    .rdx-pillars--image.-performance {
      background: url(../images/method-nav-scroll-performance-seq1.jpg) top center no-repeat;
      background-size: cover;
      margin-top: 100px;
      opacity: 0;
      -webkit-transition: margin-top 1s .5s, opacity 2s .5s;
      transition: margin-top 1s .5s, opacity 2s .5s; }
      .rdx-pillars--image.-performance.fade-in {
        margin-top: 0;
        opacity: 1; }
  .rdx-pillars--desc {
    font-family: ff-basic-gothic-pro, sans-serif;
    font-weight: 700;
    font-style: normal;
    color: #fff;
    font-size: 12px;
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    @media (min-width: 768px) {
      .rdx-pillars--desc {
        font-size: 20px; } }
    @media (min-width: 1024px) {
      .rdx-pillars--desc {
        font-size: 24px; } }
    @media (min-width: 1440px) {
      .rdx-pillars--desc {
        font-size: 28px; } }
  .rdx-split {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column;
            flex-flow: column; }
    @media (min-width: 1024px) {
      .rdx-split.-reverse {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
            -ms-flex-direction: row-reverse;
                flex-direction: row-reverse; } }
    @media (min-width: 1024px) {
      .rdx-split {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-flow: row;
                flex-flow: row; } }
    .rdx-split--content {
      -webkit-box-flex: 1;
          -ms-flex-positive: 1;
              flex-grow: 1; }
      .rdx-split--content_image {
        height: 50vh;
        margin-top: 100px;
        opacity: 0;
        -webkit-transition: margin-top 1.25s ease-out, opacity 1s ease-out;
        transition: margin-top 1.25s ease-out, opacity 1s ease-out; }
        @media (min-width: 375px) {
          .rdx-split--content_image {
            height: 50vh; } }
        @media (min-width: 1024px) {
          .rdx-split--content_image {
            height: 100vh;
            width: 50vw; } }
        .rdx-split--content_image.fade-in {
          margin-top: 0;
          opacity: 1; }
        .rdx-split--content_image.fade-delay {
          margin-top: 0;
          opacity: 1;
          -webkit-transition-delay: 1.25s;
                  transition-delay: 1.25s; }
        .rdx-split--content_image.-recovery {
          background: url(../images/recovery-5050-seq1.jpg) top center no-repeat;
          background-size: cover; }
        .rdx-split--content_image.-balance {
          background: url(../images/method-5050-balance.jpg) top center no-repeat;
          background-size: cover; }
        .rdx-split--content_image.-performance {
          background: url(../images/method-5050-performance-seq1.jpg) top center no-repeat;
          background-size: cover; }
        .rdx-split--content_image.-scifit {
          background: url(../images/science-fitness-lab-5050-equipment.jpg) top center no-repeat;
          background-size: cover; }
        .rdx-split--content_image.-atmosphere {
          background: url(../images/science-fitness-lab-5050-atmosphere.jpg) top center no-repeat;
          background-size: cover; }
        .rdx-split--content_image.-ivan {
          background: url(../images/image-performance-experts-5050-ivan.jpg) top center no-repeat;
          background-size: cover; }
        .rdx-split--content_image.-maca {
          background: url(../images/image-performance-experts-5050-maca.jpg) top center no-repeat;
          background-size: cover; }
        .rdx-split--content_image.-development {
          background: url(../images/functional-training-5050-development.jpg) top center no-repeat;
          background-size: cover; }
        .rdx-split--content_image.-fitness {
          background: url(../images/functional-training-5050-fitness-wellness.jpg) top center no-repeat;
          background-size: cover; }
      .rdx-split--content_desc {
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        padding: 25px 30px;
        line-height: 1.5;
        position: relative;
        height: 50vh;
        text-align: left;
        margin-top: 100px;
        opacity: 0;
        -webkit-transition: margin-top 1s .5s, opacity 2s .5s;
        transition: margin-top 1s .5s, opacity 2s .5s; }
        @media (min-width: 375px) {
          .rdx-split--content_desc {
            height: 50vh; } }
        @media (min-width: 1024px) {
          .rdx-split--content_desc {
            height: 100vh; } }
        .rdx-split--content_desc.-light {
          background: #fff; }
          .rdx-split--content_desc.-light .rdx-split--content_subheading,
          .rdx-split--content_desc.-light .rdx-split--content_copy {
            color: #000; }
          .rdx-split--content_desc.-light .rdx-split--content_heading {
            color: #008efc; }
          .rdx-split--content_desc.-light .btn.-line.-dark {
            color: #000; }
        .rdx-split--content_desc.fade-in {
          margin-top: 0;
          opacity: 1; }
        .rdx-split--content_desc-inner {
          -webkit-box-sizing: border-box;
                  box-sizing: border-box;
          left: 50%;
          position: absolute;
          top: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
          width: 80%; }
          @media (min-width: 768px) {
            .rdx-split--content_desc-inner {
              width: 70%; } }
          @media (min-width: 1024px) {
            .rdx-split--content_desc-inner {
              width: 80%; } }
          @media (min-width: 1440px) {
            .rdx-split--content_desc-inner {
              width: 50%; } }
          @media (min-width: 1920px) {
            .rdx-split--content_desc-inner {
              width: 60%; } }
        @media (min-width: 768px) {
          .rdx-split--content_desc {
            padding: 50px 150px; } }
        @media (min-width: 1024px) {
          .rdx-split--content_desc {
            height: 100vh;
            padding: 100px 50px;
            width: 50vw; } }
      .rdx-split--content_heading {
        font-family: ff-basic-gothic-pro, sans-serif;
        font-weight: 700;
        font-style: normal;
        color: #fff;
        font-size: 28px;
        line-height: 1.5;
        margin: 0;
        margin-bottom: 10px;
        padding: 0;
        text-transform: uppercase; }
        @media (min-width: 768px) {
          .rdx-split--content_heading {
            font-size: 42px; } }
        @media (min-width: 1024px) {
          .rdx-split--content_heading {
            line-height: 1.25;
            font-size: 64px; } }
      .rdx-split--content_subheading {
        font-family: ff-basic-gothic-pro, sans-serif;
        font-weight: 300;
        font-style: italic;
        color: #fff;
        font-size: 16px;
        margin: 0;
        padding: 4px 0 8px; }
        @media (min-width: 768px) {
          .rdx-split--content_subheading {
            font-size: 20px;
            padding: 10px 0; } }
      .rdx-split--content_copy {
        font-family: ff-basic-gothic-pro, sans-serif;
        font-weight: 200;
        font-style: normal;
        color: #fff;
        font-size: 10px;
        line-height: 1.5;
        margin: 0;
        padding: 0; }
        @media (min-width: 768px) {
          .rdx-split--content_copy {
            font-size: 15px;
            width: 100%; } }
        @media (min-width: 1440px) {
          .rdx-split--content_copy {
            font-size: 15px;
            width: 100%; } }
        @media (min-width: 1920px) {
          .rdx-split--content_copy {
            font-size: 15px;
            width: 50%; } }
        @media (min-width: 2560px) {
          .rdx-split--content_copy {
            font-size: 15px;
            width: 60%; } }
  .rdx-full {
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }
    @media (min-width: 1024px) {
      .rdx-full.-reverse .rdx-full--content_image-wrapper {
        left: 10%; } }
    .rdx-full--content_image {
      height: 50vh;
      padding: 85px 40px 100px;
      text-align: center;
      margin-top: 100px;
      opacity: 0;
      -webkit-transition: margin-top 1s .5s, opacity 2s .5s;
      transition: margin-top 1s .5s, opacity 2s .5s; }
      .rdx-full--content_image.fade-in {
        margin-top: 0;
        opacity: 1; }
      .rdx-full--content_image.-recovery {
        background: url(../images/image-method-recovery-background.jpg) top center no-repeat;
        background-size: cover; }
      .rdx-full--content_image.-rehab {
        background: url(../images/functional-training-100-rehab.jpg) top center no-repeat;
        background-size: cover; }
      @media (min-width: 768px) {
        .rdx-full--content_image {
          padding: 180px 150px; } }
      @media (min-width: 1024px) {
        .rdx-full--content_image {
          height: 100vh;
          padding: 0;
          position: relative; }
          .rdx-full--content_image-wrapper {
            position: absolute;
            right: 10%;
            top: 50%;
            -webkit-transform: translateY(-50%);
                    transform: translateY(-50%);
            width: 40%;
            text-align: left;
            margin-top: 100px;
            opacity: 0;
            -webkit-transition: margin-top 1s .5s, opacity 2s .5s;
            transition: margin-top 1s .5s, opacity 2s .5s; }
            .rdx-full--content_image-wrapper.fade-in {
              margin-top: 0;
              opacity: 1; } }
      @media (min-width: 1440px) {
        .rdx-full--content_image {
          height: 100vh;
          padding: 0;
          position: relative; }
          .rdx-full--content_image-wrapper {
            position: absolute;
            right: 0;
            top: 50%;
            -webkit-transform: translateY(-50%);
                    transform: translateY(-50%);
            width: 40%;
            text-align: left;
            margin-top: 100px;
            opacity: 0;
            -webkit-transition: margin-top 1s .5s, opacity 2s .5s;
            transition: margin-top 1s .5s, opacity 2s .5s; }
            .rdx-full--content_image-wrapper.fade-in {
              margin-top: 0;
              opacity: 1; } }
      @media (min-width: 1920px) {
        .rdx-full--content_image-wrapper {
          width: 30%; } }
    .rdx-full--content_desc {
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      height: 30vh;
      padding: 25px 30px;
      position: relative;
      text-align: center; }
    .rdx-full--content_heading {
      font-family: ff-basic-gothic-pro, sans-serif;
      font-weight: 700;
      font-style: normal;
      color: #fff;
      font-size: 28px;
      line-height: 1.5;
      margin: 0;
      text-transform: uppercase;
      padding: 0; }
      @media (min-width: 768px) {
        .rdx-full--content_heading {
          font-size: 64px; } }
    .rdx-full--content_subheading {
      font-family: ff-basic-gothic-pro, sans-serif;
      font-weight: 300;
      font-style: italic;
      color: #fff;
      font-size: 16px;
      margin: 0;
      padding: 4px 0 8px; }
      @media (min-width: 768px) {
        .rdx-full--content_subheading {
          font-size: 20px; } }
    .rdx-full--content_copy {
      font-family: ff-basic-gothic-pro, sans-serif;
      font-weight: 200;
      font-style: normal;
      color: #fff;
      font-size: 10px;
      line-height: 1.5;
      margin: 0;
      padding: 0; }
      @media (min-width: 768px) {
        .rdx-full--content_copy {
          font-size: 16px;
          width: 100%; } }
      @media (min-width: 1440px) {
        .rdx-full--content_copy {
          font-size: 16px;
          width: 75%; } }
      @media (min-width: 1920px) {
        .rdx-full--content_copy {
          font-size: 16px;
          width: 50%; } }
  .rdx-action--strip {
    font-family: ff-basic-gothic-pro, sans-serif;
    font-weight: 700;
    font-style: normal;
    background: #000523;
    color: #fff;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 25px 20px;
    text-align: center;
    width: 100vw;
    margin-top: 100px;
    opacity: 0;
    -webkit-transition: margin-top 1s .5s, opacity 2s .5s;
    transition: margin-top 1s .5s, opacity 2s .5s; }
    .rdx-action--strip.fade-in {
      margin-top: 0;
      opacity: 1; }
    @media (min-width: 768px) {
      .rdx-action--strip {
        padding: 50px 150px; }
        .rdx-action--strip .btn {
          margin: 25px 0; } }
    @media (min-width: 1024px) {
      .rdx-action--strip {
        padding: 50px 150px;
        min-height: 370px; }
        .rdx-action--strip .btn {
          margin: 25px 0 0 0; } }
    @media (min-width: 1440px) {
      .rdx-action--strip {
        padding: 80px 250px; }
        .rdx-action--strip .btn {
          margin: 0; } }
  .rdx-action--text {
    font-size: 26px; }
    @media (min-width: 1024px) {
      .rdx-action--text {
        font-size: 36px;
        padding: 0 75px; } }
    @media (min-width: 1440px) {
      .rdx-action--text {
        font-size: 36px;
        padding: 0 140px; } }
    @media (min-width: 1920px) {
      .rdx-action--text {
        font-size: 36px;
        padding: 0 400px; } }
    @media (min-width: 2560px) {
      .rdx-action--text {
        font-size: 36px;
        padding: 0 700px; } }
  .rdx-footer {
    background: #000523;
    color: #fff;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 40px 25px;
    position: relative;
    margin-top: 100px;
    opacity: 0;
    -webkit-transition: margin-top 1s .5s, opacity 2s .5s;
    transition: margin-top 1s .5s, opacity 2s .5s; }
    .rdx-footer.fade-in {
      margin-top: 0;
      opacity: 1; }
    @media (min-width: 1024px) {
      .rdx-footer {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        min-height: 200px;
        padding: 40px 20px; } }
    @media (min-width: 1440px) {
      .rdx-footer {
        padding: 40px 120px; } }
    @media (min-width: 1920px) {
      .rdx-footer {
        padding: 40px 400px; } }
    @media (min-width: 2560px) {
      .rdx-footer {
        padding: 40px 700px; } }
    .rdx-footer--box {
      -webkit-box-flex: 1;
          -ms-flex-positive: 1;
              flex-grow: 1;
      position: relative; }
      .rdx-footer--box.-logo {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column; }
        @media (min-width: 1024px) {
          .rdx-footer--box.-logo {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
                -ms-flex-direction: row;
                    flex-direction: row;
            width: 100px; } }
      @media (min-width: 1024px) {
        .rdx-footer--box.-info {
          width: 0; }
          .rdx-footer--box.-info.-small {
            padding-left: 40px; }
          .rdx-footer--box.-info.-stack {
            padding-left: 15px; } }
      @media (min-width: 1440px) {
        .rdx-footer--box.-info {
          width: 0; }
          .rdx-footer--box.-info.-small {
            padding-left: 40px; }
          .rdx-footer--box.-info.-stack {
            padding-left: 40px; } }
      @media (min-width: 1024px) {
        .rdx-footer--box.-slogan {
          line-height: 1.3;
          width: 50px; } }
      @media (min-width: 1024px) {
        .rdx-footer--box.-social {
          width: 0; } }
    .rdx-footer--logo {
      text-align: center; }
      @media (min-width: 1024px) {
        .rdx-footer--logo .rdx-logo--svg {
          height: 100%;
          width: 100%; } }
      @media (min-width: 1024px) {
        .rdx-footer--logo {
          height: 50px;
          width: 50px; } }
    .rdx-footer--heading_wrapper {
      position: relative;
      text-transform: uppercase;
      text-align: center; }
      @media (min-width: 1024px) {
        .rdx-footer--heading_wrapper {
          text-align: left;
          padding: 5px; } }
    .rdx-footer--heading {
      font-family: ff-basic-gothic-pro, sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 24px;
      margin: 0;
      padding: 0; }
      @media (min-width: 768px) {
        .rdx-footer--heading {
          font-size: 32px; } }
      @media (min-width: 1024px) {
        .rdx-footer--heading {
          font-size: 17px; } }
    .rdx-footer--subheading {
      font-family: ff-basic-gothic-pro, sans-serif;
      font-weight: 700;
      font-style: italic;
      font-size: 12px;
      margin: 0;
      padding: 5px 25px 15px;
      text-align: center; }
      @media (min-width: 768px) {
        .rdx-footer--subheading {
          font-size: 16px; } }
      @media (min-width: 1024px) {
        .rdx-footer--subheading {
          padding: 7px 0;
          text-align: left; } }
    .rdx-footer--address_outer-wrapper .rdx-footer--address {
      color: #fff;
      display: block;
      text-decoration: none; }
      .rdx-footer--address_outer-wrapper .rdx-footer--address:hover {
        color: #008efc; }
    .rdx-footer--address_outer-wrapper.-links {
      margin: 15px 0 0 0; }
    @media (min-width: 1024px) {
      .rdx-footer--address_outer-wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column; }
        .rdx-footer--address_outer-wrapper.-links {
          margin: 0; } }
    .rdx-footer--address_wrapper {
      position: relative;
      text-transform: uppercase;
      text-align: center; }
      @media (min-width: 1024px) {
        .rdx-footer--address_wrapper.-contact {
          margin: 10px 0 0 0; } }
      @media (min-width: 1024px) {
        .rdx-footer--address_wrapper {
          text-align: left; } }
    .rdx-footer--address {
      font-family: ff-basic-gothic-pro, sans-serif;
      font-weight: 200;
      font-style: normal;
      font-size: 12px;
      margin: 0;
      padding: 10px 0 0 0; }
      @media (min-width: 768px) {
        .rdx-footer--address {
          font-size: 14px; } }
      @media (min-width: 1024px) {
        .rdx-footer--address {
          font-size: 10px; } }
      @media (min-width: 1440px) {
        .rdx-footer--address {
          font-size: 11px; } }

.rdx {
  overflow-x: hidden; }
  .rdx-contact--label {
    font-family: ff-basic-gothic-pro, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 10px;
    color: #008efc; }
  .rdx-telephone--contact {
    font-family: ff-basic-gothic-pro, sans-serif;
    font-weight: 200;
    font-style: normal; }
    .rdx-telephone--contact.-dark {
      color: #1c1c1c; }
  .rdx-telephone--number {
    font-family: ff-basic-gothic-pro, sans-serif;
    font-weight: 600;
    font-style: normal;
    color: #008efc;
    cursor: pointer;
    letter-spacing: -1px;
    text-decoration: none; }
    .rdx-telephone--number:hover {
      text-decoration: underline; }
    .rdx-telephone--number.-dark {
      color: #1c1c1c; }
  .rdx-banner--text {
    font-family: ff-basic-gothic-pro, sans-serif;
    font-weight: 900;
    font-style: italic;
    color: #fff;
    font-size: 40px;
    margin: 0;
    text-transform: uppercase;
    z-index: 9; }
    @media (min-width: 400px) {
      .rdx-banner--text {
        font-size: 42px; } }
    @media (min-width: 768px) {
      .rdx-banner--text {
        font-size: 60px; } }
    @media (min-width: 1024px) {
      .rdx-banner--text {
        font-size: 64px;
        line-height: 1; } }
    @media (min-width: 1440px) {
      .rdx-banner--text {
        font-size: 90px; } }
    @media (min-width: 1920px) {
      .rdx-banner--text {
        font-size: 130px; } }
  .rdx-banner--subtext {
    font-family: ff-basic-gothic-pro, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #fff;
    display: none;
    font-size: 10px;
    width: 100%;
    z-index: 9; }
    @media (min-width: 768px) {
      .rdx-banner--subtext {
        display: block;
        font-size: 14px;
        line-height: 1.5;
        width: 60%; } }
    @media (min-width: 1440px) {
      .rdx-banner--subtext {
        font-size: 18px;
        line-height: 1.5;
        width: 60%; } }
    @media (min-width: 1920px) {
      .rdx-banner--subtext {
        font-size: 18px;
        line-height: 1.55; } }
    @media (min-width: 1920px) {
      .rdx-banner--subtext {
        font-size: 18px;
        line-height: 1.55;
        width: 42%; } }
    @media (min-width: 2560px) {
      .rdx-banner--subtext {
        font-size: 18px;
        line-height: 1.55;
        width: 45%; } }
  .rdx-banner--video {
    overflow: hidden;
    position: relative;
    width: 100vw; }
    @media (min-width: 768px) {
      .rdx-banner--video {
        max-height: 430px; } }
    @media (min-width: 768px) {
      .rdx-banner--video {
        max-height: 575px; } }
    @media (min-width: 1440px) {
      .rdx-banner--video {
        max-height: 810px; } }
    @media (min-width: 1920px) {
      .rdx-banner--video {
        max-height: 100vh; } }
  .rdx-contact--form {
    margin: 20px auto 0;
    width: 90%; }
    @media (min-width: 768px) {
      .rdx-contact--form {
        width: 85%; } }
    @media (min-width: 1024px) {
      .rdx-contact--form {
        margin: 100px auto 0;
        width: 95%; } }
    @media (min-width: 1440px) {
      .rdx-contact--form {
        margin: 150px auto 0;
        width: 95%; } }
    @media (min-width: 2560px) {
      .rdx-contact--form {
        margin: 150px 0 0 150px;
        width: 90%; } }
    .rdx-contact--form .form-elements {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column; }
      @media (min-width: 1024px) {
        .rdx-contact--form .form-elements {
          bottom: 200px;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-direction: row;
                  flex-direction: row;
          margin: auto;
          max-width: 90vw; } }
      .rdx-contact--form .form-elements .button-success {
        font-family: ff-basic-gothic-pro, sans-serif;
        font-weight: 600;
        font-style: normal;
        background: #000523;
        border-radius: 50px;
        border: 0;
        -webkit-box-shadow: inset 0 0 0 2px #008efc;
                box-shadow: inset 0 0 0 2px #008efc;
        color: #fff;
        font-size: 14px;
        margin: 15px auto 30px;
        padding: 15px 50px;
        text-transform: uppercase;
        -webkit-transition: all .35s ease-out;
        transition: all .35s ease-out; }
        .rdx-contact--form .form-elements .button-success:hover {
          background: #008efc;
          color: #fff;
          cursor: pointer; }
        @media (min-width: 768px) {
          .rdx-contact--form .form-elements .button-success {
            font-size: 24px;
            margin: 20px auto 30px;
            padding: 20px 80px; } }
        @media (min-width: 1024px) {
          .rdx-contact--form .form-elements .button-success {
            font-size: 18px;
            margin: 15px auto;
            padding: 10px 40px; } }
        @media (min-width: 1440px) {
          .rdx-contact--form .form-elements .button-success {
            margin: 12px auto; } }
        @media (min-width: 1920px) {
          .rdx-contact--form .form-elements .button-success {
            margin: 10px auto;
            padding: 10px 60px; } }
    .rdx-contact--form .pure-group {
      border: 0; }
      .rdx-contact--form .pure-group.-input-type {
        margin: 10px 0; }
        @media (min-width: 768px) {
          .rdx-contact--form .pure-group.-input-type {
            margin: 20px 0; } }
        @media (min-width: 1024px) {
          .rdx-contact--form .pure-group.-input-type {
            margin: 0 0;
            padding-left: 10px;
            padding-right: 10px;
            width: 25%; } }
      .rdx-contact--form .pure-group.-input-button {
        margin: 5px 0; }
        @media (min-width: 1024px) {
          .rdx-contact--form .pure-group.-input-button {
            padding-left: 0;
            padding-right: 7px;
            width: 20%; } }
      .rdx-contact--form .pure-group--label {
        font-family: ff-basic-gothic-pro, sans-serif;
        font-weight: 600;
        font-style: normal;
        color: #fff;
        display: block;
        font-size: 10px;
        margin-bottom: 10px;
        text-transform: uppercase; }
        @media (min-width: 400px) {
          .rdx-contact--form .pure-group--label {
            font-size: 14px; } }
        @media (min-width: 1024px) {
          .rdx-contact--form .pure-group--label {
            margin-bottom: 0; } }
      .rdx-contact--form .pure-group--input {
        font-family: ff-basic-gothic-pro, sans-serif;
        font-weight: 600;
        font-style: normal;
        background: transparent;
        border: 0;
        border-bottom: thin solid #fff;
        color: #fff;
        font-size: 14px;
        outline: 0;
        padding: 10px;
        text-transform: uppercase;
        width: 100%; }
        @media (min-width: 1024px) {
          .rdx-contact--form .pure-group--input {
            padding: 20px 10px 10px; } }
        @media (min-width: 1280px) {
          .rdx-contact--form .pure-group--input {
            font-size: 24px;
            padding: 10px 10px 10px; } }
        @media (min-width: 1920px) {
          .rdx-contact--form .pure-group--input {
            padding: 20px 10px 10px; } }
        @media (min-width: 2560px) {
          .rdx-contact--form .pure-group--input {
            padding: 20px 10px 10px; } }
      .rdx-contact--form .pure-group--text_call {
        font-family: ff-basic-gothic-pro, sans-serif;
        font-weight: 600;
        font-style: normal;
        background: transparent;
        border: 0;
        border: thin solid #fff;
        color: #fff;
        cursor: pointer;
        font-size: 14px;
        margin: 0 2.5%;
        padding: 10px 0;
        text-transform: uppercase;
        width: 45%;
        float: left; }
        .rdx-contact--form .pure-group--text_call[data-active="true"] {
          border: thin solid #008efc; }
          .rdx-contact--form .pure-group--text_call[data-active="true"]:focus {
            outline: 0; }
        @media (min-width: 768px) {
          .rdx-contact--form .pure-group--text_call {
            font-size: 18px;
            padding: 20px 0; } }
        @media (min-width: 1024px) {
          .rdx-contact--form .pure-group--text_call {
            padding: 18px 0;
            margin: 0 1%;
            width: 48%; } }
      .rdx-contact--form .pure-group.honeypot-field {
        display: none; }

.rdx-preloader {
  background: #000523;
  height: 100vh;
  left: 0;
  position: absolute;
  top: 0;
  opacity: 1;
  -webkit-transition: opacity 3s ease-out;
  transition: opacity 3s ease-out;
  width: 100vw;
  z-index: 99999999999; }
  .rdx-preloader[data-active='false'] {
    opacity: 0; }

@-webkit-keyframes svg-fill {
  0% {
    y: 250; }
  100% {
    y: 0; } }

@keyframes svg-fill {
  0% {
    y: 250; }
  100% {
    y: 0; } }
  .rdx-preloader #mask1 .rect--svg {
    stroke: none;
    fill: white;
    height: 200px;
    width: 200px;
    y: 250; }
    .rdx-preloader #mask1 .rect--svg[data-active="true"] {
      y: unset;
      -webkit-animation: svg-fill;
              animation: svg-fill;
      -webkit-animation-duration: 7s;
              animation-duration: 7s;
      -webkit-animation-iteration-count: 1;
              animation-iteration-count: 1; }
  .rdx-preloader .rdx-prealoder--svg_mask {
    left: 50%;
    overflow: hidden;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -webkit-transition: all 1s ease-out;
    transition: all 1s ease-out;
    height: 200px;
    width: 200px;
    z-index: 5; }
  .rdx-preloader .rdx-prealoder--svg_masked {
    background: url(../images/radix-logo-blue.svg) center center no-repeat;
    left: 0;
    position: absolute;
    bottom: 0;
    height: 200px;
    width: 200px;
    z-index: 5; }
  .rdx-preloader .rdx-logo--svg_fill {
    fill: #008efc;
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -webkit-transition: all .45s ease-out;
    transition: all .45s ease-out;
    height: 200px;
    width: 200px;
    z-index: 1; }
  .rdx-preloader .rdx-logo--svg {
    fill: #010b40;
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    height: 200px;
    width: 200px;
    z-index: 1; }

.rdx-panoramic {
  opacity: 0; }
  .rdx-panoramic[data-active='true'] {
    opacity: 1; }
  .rdx-panoramic--bumper[data-dir='prev'], .rdx-panoramic--bumper[data-dir='next'] {
    cursor: pointer;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    text-align: center;
    width: 25px; }
    .rdx-panoramic--bumper[data-dir='prev'] .rdx-icon--arrow, .rdx-panoramic--bumper[data-dir='next'] .rdx-icon--arrow {
      fill: #fff; }
    @media (min-width: 1024px) {
      .rdx-panoramic--bumper[data-dir='prev'], .rdx-panoramic--bumper[data-dir='next'] {
        font-size: 70px; } }
  .rdx-panoramic--bumper[data-dir='prev'] {
    left: 20px; }
    @media (min-width: 1024px) {
      .rdx-panoramic--bumper[data-dir='prev'] {
        left: 50px; } }
  .rdx-panoramic--bumper[data-dir='next'] {
    right: 20px; }
    @media (min-width: 1024px) {
      .rdx-panoramic--bumper[data-dir='next'] {
        right: 50px; } }

.rdx-orbs {
  left: 0;
  position: absolute;
  top: 0; }
  .rdx-orbs--point {
    background: rgba(0, 142, 252, 0.25);
    border-radius: 100%;
    height: 55px;
    width: 55px; }
    @media (min-width: 768px) {
      .rdx-orbs--point {
        height: 75px;
        width: 75px; } }
    .rdx-orbs--point:hover {
      cursor: pointer; }
      .rdx-orbs--point:hover:before {
        border: 5px solid #008efc;
        height: 20px;
        width: 20px; }
        @media (min-width: 768px) {
          .rdx-orbs--point:hover:before {
            height: 30px;
            width: 30px; } }
    .rdx-orbs--point:before {
      border: 2px solid #008efc;
      border-radius: 100%;
      content: '';
      height: 15px;
      left: 50%;
      position: absolute;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      -webkit-transition: all .35s ease-out;
      transition: all .35s ease-out;
      width: 15px; }
      @media (min-width: 768px) {
        .rdx-orbs--point:before {
          height: 25px;
          width: 25px; } }
    .rdx-orbs--point[data-detail='pillars'] {
      height: 5px;
      left: 10vw;
      opacity: 0;
      position: absolute;
      top: 20vh;
      -webkit-transition: all .45s .25s ease-out;
      transition: all .45s .25s ease-out;
      -webkit-transform-origin: center;
              transform-origin: center;
      width: 5px; }
      .rdx-orbs--point[data-detail='pillars'][data-active='true'] {
        opacity: 1;
        height: 55px;
        width: 55px; }
      @media (min-width: 768px) {
        .rdx-orbs--point[data-detail='pillars'] {
          left: 30vw;
          top: 15vh; }
          .rdx-orbs--point[data-detail='pillars'][data-active='true'] {
            opacity: 1;
            height: 75px;
            width: 75px; } }
    .rdx-orbs--point[data-detail='balance'] {
      height: 5px;
      left: 60vw;
      opacity: 0;
      position: absolute;
      top: 60vh;
      -webkit-transition: all .45s .25s ease-out;
      transition: all .45s .25s ease-out;
      -webkit-transform-origin: center;
              transform-origin: center;
      width: 5px; }
      .rdx-orbs--point[data-detail='balance'][data-active='true'] {
        opacity: 1;
        height: 55px;
        width: 55px; }
      @media (min-width: 768px) {
        .rdx-orbs--point[data-detail='balance'] {
          left: 60vw;
          top: 55vh; }
          .rdx-orbs--point[data-detail='balance'][data-active='true'] {
            opacity: 1;
            height: 75px;
            width: 75px; } }
    .rdx-orbs--point[data-detail='experts'] {
      height: 5px;
      left: 35vw;
      opacity: 0;
      position: absolute;
      top: 35vh;
      -webkit-transition: all .45s .25s ease-out;
      transition: all .45s .25s ease-out;
      -webkit-transform-origin: center;
              transform-origin: center;
      width: 5px; }
      .rdx-orbs--point[data-detail='experts'][data-active='true'] {
        opacity: 1;
        height: 55px;
        width: 55px; }
      @media (min-width: 768px) {
        .rdx-orbs--point[data-detail='experts'] {
          left: 30vw;
          top: 30vh; }
          .rdx-orbs--point[data-detail='experts'][data-active='true'] {
            opacity: 1;
            height: 75px;
            width: 75px; } }
    .rdx-orbs--point[data-detail='equipment'] {
      height: 5px;
      left: 75vw;
      opacity: 0;
      position: absolute;
      top: 55vh;
      -webkit-transition: all .45s .25s ease-out;
      transition: all .45s .25s ease-out;
      -webkit-transform-origin: center;
              transform-origin: center;
      width: 5px; }
      .rdx-orbs--point[data-detail='equipment'][data-active='true'] {
        opacity: 1;
        height: 55px;
        width: 55px; }
      @media (min-width: 768px) {
        .rdx-orbs--point[data-detail='equipment'] {
          left: 70vw;
          top: 50vh; }
          .rdx-orbs--point[data-detail='equipment'][data-active='true'] {
            opacity: 1;
            height: 75px;
            width: 75px; } }
    .rdx-orbs--point[data-detail='performance'] {
      height: 5px;
      left: 10vw;
      opacity: 0;
      position: absolute;
      top: 20vh;
      -webkit-transition: all .45s .25s ease-out;
      transition: all .45s .25s ease-out;
      -webkit-transform-origin: center;
              transform-origin: center;
      width: 5px; }
      .rdx-orbs--point[data-detail='performance'][data-active='true'] {
        opacity: 1;
        height: 55px;
        width: 55px; }
      @media (min-width: 768px) {
        .rdx-orbs--point[data-detail='performance'] {
          left: 25vw;
          top: 55vh; }
          .rdx-orbs--point[data-detail='performance'][data-active='true'] {
            opacity: 1;
            height: 75px;
            width: 75px; } }
    .rdx-orbs--point[data-detail='approach'] {
      height: 5px;
      left: 40vw;
      opacity: 0;
      position: absolute;
      top: 45vh;
      -webkit-transition: all .45s .25s ease-out;
      transition: all .45s .25s ease-out;
      -webkit-transform-origin: center;
              transform-origin: center;
      width: 5px; }
      .rdx-orbs--point[data-detail='approach'][data-active='true'] {
        opacity: 1;
        height: 55px;
        width: 55px; }
      @media (min-width: 768px) {
        .rdx-orbs--point[data-detail='approach'] {
          left: 45vw;
          top: 45vh; }
          .rdx-orbs--point[data-detail='approach'][data-active='true'] {
            opacity: 1;
            height: 75px;
            width: 75px; } }
    .rdx-orbs--point[data-detail='recovery'] {
      height: 5px;
      left: 10vw;
      opacity: 0;
      position: absolute;
      top: 55vh;
      -webkit-transition: all .45s .25s ease-out;
      transition: all .45s .25s ease-out;
      -webkit-transform-origin: center;
              transform-origin: center;
      width: 5px; }
      .rdx-orbs--point[data-detail='recovery'][data-active='true'] {
        opacity: 1;
        height: 55px;
        width: 55px; }
      @media (min-width: 768px) {
        .rdx-orbs--point[data-detail='recovery'] {
          left: 35vw;
          top: 50vh; }
          .rdx-orbs--point[data-detail='recovery'][data-active='true'] {
            opacity: 1;
            height: 75px;
            width: 75px; } }
      @media (min-width: 1280px) {
        .rdx-orbs--point[data-detail='recovery'] {
          left: 25vw;
          top: 50vh; }
          .rdx-orbs--point[data-detail='recovery'][data-active='true'] {
            opacity: 1;
            height: 75px;
            width: 75px; } }
    .rdx-orbs--point[data-detail='level'] {
      height: 5px;
      left: 60vw;
      opacity: 0;
      position: absolute;
      top: 25vh;
      -webkit-transition: all .45s .25s ease-out;
      transition: all .45s .25s ease-out;
      -webkit-transform-origin: center;
              transform-origin: center;
      width: 5px; }
      .rdx-orbs--point[data-detail='level'][data-active='true'] {
        opacity: 1;
        height: 55px;
        width: 55px; }
      @media (min-width: 768px) {
        .rdx-orbs--point[data-detail='level'] {
          left: 60vw;
          top: 20vh; }
          .rdx-orbs--point[data-detail='level'][data-active='true'] {
            opacity: 1;
            height: 75px;
            width: 75px; } }
      @media (min-width: 1280px) {
        .rdx-orbs--point[data-detail='level'] {
          left: 60vw;
          top: 25vh; }
          .rdx-orbs--point[data-detail='level'][data-active='true'] {
            opacity: 1;
            height: 75px;
            width: 75px; } }

.rdx-modal {
  background: #000523;
  display: none;
  height: 95%;
  left: 50%;
  opacity: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transition: all .45s ease-out;
  transition: all .45s ease-out;
  width: 95%;
  z-index: 100; }
  @media (min-width: 375px) and (min-height: 812px) {
    .rdx-modal {
      height: 75%; } }
  @media (min-width: 768px) {
    .rdx-modal .-cta.btn {
      margin: 50px 0; } }
  @media (min-width: 1024px) {
    .rdx-modal {
      height: 70%;
      width: 80%; } }
  @media (min-width: 2560px) {
    .rdx-modal {
      width: 70%; } }
  .rdx-modal[data-active='true'] {
    opacity: 1; }
  .rdx-modal--exit {
    height: 30px;
    position: absolute;
    right: 30px;
    top: 30px;
    width: 30px;
    z-index: 1; }
    .rdx-modal--exit_svg {
      height: 100%;
      width: 100%; }
  .rdx-modal--content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: 100%; }
    @media (min-width: 1024px) {
      .rdx-modal--content {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row; } }
    .rdx-modal--content_image {
      background: url(../images/overview-tour-1a-modal-balance.jpg) top center no-repeat;
      background-size: cover;
      height: 70%;
      width: 100%; }
      @media (min-width: 1024px) {
        .rdx-modal--content_image {
          height: 100%;
          width: 50%; } }
    .rdx-modal--content_copy {
      color: #fff;
      height: 100%;
      position: relative;
      width: 100%; }
      .rdx-modal--content_copy-inner {
        width: 90%; }
        @media (min-width: 1024px) {
          .rdx-modal--content_copy-inner {
            width: 65%; } }
        @media (min-width: 1920px) {
          .rdx-modal--content_copy-inner {
            width: 75%; } }
      @media (min-width: 1024px) {
        .rdx-modal--content_copy {
          width: 50%; } }
      .rdx-modal--content_copy-header {
        font-family: ff-basic-gothic-pro, sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 30px;
        margin: 0 0 5px 0;
        padding: 0; }
        @media (min-width: 768px) {
          .rdx-modal--content_copy-header {
            font-size: 38px; } }
        @media (min-width: 1024px) {
          .rdx-modal--content_copy-header {
            font-size: 42px;
            margin: 0 0 15px 0; } }
      .rdx-modal--content_copy-text {
        font-family: ff-basic-gothic-pro, sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 12px;
        line-height: 1.5;
        max-height: 130px;
        min-height: 100px;
        margin: 0;
        overflow-y: auto;
        padding: 0; }
        @media (min-width: 768px) {
          .rdx-modal--content_copy-text {
            font-size: 16px; } }
        @media (min-width: 1024px) {
          .rdx-modal--content_copy-text {
            max-height: 180px; } }
      .rdx-modal--content_copy-inner {
        left: 50%;
        position: absolute;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }

.rdx {
  background: #000523;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: absolute;
  height: 100vh;
  overflow: hidden;
  width: 100vw; }
  .rdx.-light {
    background: #fff; }
  .rdx-video {
    background: rgba(0, 0, 0, 0.8);
    display: none;
    height: 100vh;
    left: 0;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: 99999; }
    .rdx-video[data-active="true"] {
      display: block; }
    .rdx-video--exit {
      right: -40px;
      position: absolute;
      top: -40px;
      z-index: 99999;
      color: #fff;
      font-size: 48px;
      height: 40px;
      width: 40px; }
    .rdx-video--bar {
      background: #fff;
      height: 3px;
      position: absolute;
      top: 50%;
      width: 40px; }
      .rdx-video--bar:nth-child(1) {
        -webkit-transform: translateY(-50%) rotate(45deg);
                transform: translateY(-50%) rotate(45deg); }
      .rdx-video--bar:nth-child(2) {
        -webkit-transform: translateY(-50%) rotate(-45deg);
                transform: translateY(-50%) rotate(-45deg); }
    .rdx-video--overlay {
      left: 50%;
      position: absolute;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      width: 60%; }
    .rdx-video--player {
      width: 100%;
      height: auto; }
      .rdx-video--player:focus {
        outline: none; }
      .rdx-video--player._btn {
        display: none; }
      .rdx-video--player.-banner {
        display: none;
        left: 0;
        position: absolute;
        top: 0;
        z-index: 0; }
        @media (min-width: 1024px) {
          .rdx-video--player.-banner {
            display: block; } }
  .rdx-logo {
    font-family: ff-basic-gothic-pro, sans-serif;
    font-weight: 700;
    font-style: normal;
    color: #008efc;
    left: 8px;
    position: absolute;
    top: 8px;
    -webkit-transition: all .25s ease-out;
    transition: all .25s ease-out;
    z-index: 99; }
    .rdx-logo:hover {
      cursor: pointer; }
    .rdx-logo--svg {
      fill: #008efc;
      height: 50px;
      width: 50px; }
      @media (min-width: 768px) {
        .rdx-logo--svg {
          height: 100px;
          width: 100px; } }
    .rdx-logo[data-active="true"] .rdx-logo--svg {
      fill: #1c1c1c; }
    @media (min-width: 1024px) {
      .rdx-logo {
        left: 10px;
        top: 10px; } }
    @media (min-width: 1280px) {
      .rdx-logo {
        left: 20px;
        top: 25px; } }
  .rdx-dropdown {
    background: #000523;
    background: -webkit-gradient(linear, left top, left bottom, from(#000523), to(#000523));
    background: linear-gradient(to bottom, #000523 0%, #000523 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$dark-base, endColorstr=$dark-base,GradientType=1);
    display: none;
    height: 100vh;
    left: 0;
    opacity: 0;
    position: absolute;
    -webkit-transition: all .5s ease-out;
    transition: all .5s ease-out;
    top: 0;
    width: 100vw; }
    .rdx-dropdown[data-active="true"] {
      opacity: 1;
      z-index: 15; }
    .rdx-dropdown--list {
      font-family: ff-basic-gothic-pro, sans-serif;
      font-weight: 700;
      font-style: italic;
      margin: 0;
      list-style-type: none;
      left: 50%;
      min-height: 260px;
      padding: 0;
      position: absolute;
      top: 40%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      text-transform: uppercase;
      width: 85vw;
      z-index: 5; }
      @media (min-width: 375px) and (min-height: 812px) {
        .rdx-dropdown--list {
          top: 28%; } }
      @media (min-width: 768px) {
        .rdx-dropdown--list {
          min-height: 400px;
          top: 40%; } }
      @media (min-width: 1024px) {
        .rdx-dropdown--list {
          min-height: 430px;
          left: 45%;
          top: 45%;
          width: 65vw; } }
      @media (min-width: 1280px) {
        .rdx-dropdown--list {
          min-height: 500px;
          left: 50%;
          width: 70vw; } }
      @media (min-width: 1920px) {
        .rdx-dropdown--list {
          left: 45%;
          min-height: 580px; } }
      .rdx-dropdown--list[data-active="true"] .rdx-dropdown--list_item {
        opacity: 1; }
        .rdx-dropdown--list[data-active="true"] .rdx-dropdown--list_item.-cta {
          bottom: 0;
          opacity: 1;
          -webkit-transition: bottom .75s ease-out .80s,  opacity .75s ease-out .80s,  background-color .35s ease-out, color .35s ease-out, -webkit-box-shadow .35s ease-out;
          transition: bottom .75s ease-out .80s,  opacity .75s ease-out .80s,  background-color .35s ease-out, color .35s ease-out, -webkit-box-shadow .35s ease-out;
          transition: bottom .75s ease-out .80s,  opacity .75s ease-out .80s,  background-color .35s ease-out, color .35s ease-out, box-shadow .35s ease-out;
          transition: bottom .75s ease-out .80s,  opacity .75s ease-out .80s,  background-color .35s ease-out, color .35s ease-out, box-shadow .35s ease-out,  -webkit-box-shadow .35s ease-out; }
        .rdx-dropdown--list[data-active="true"] .rdx-dropdown--list_item:nth-child(1) {
          left: 0;
          top: 0; }
        .rdx-dropdown--list[data-active="true"] .rdx-dropdown--list_item:nth-child(2) {
          left: 0;
          top: 50px; }
        .rdx-dropdown--list[data-active="true"] .rdx-dropdown--list_item:nth-child(3) {
          left: 0;
          top: 100px; }
        .rdx-dropdown--list[data-active="true"] .rdx-dropdown--list_item:nth-child(4) {
          left: 0;
          top: 150px; }
        @media (min-width: 768px) {
          .rdx-dropdown--list[data-active="true"] .rdx-dropdown--list_item:nth-child(1) {
            left: 0;
            top: 0; }
          .rdx-dropdown--list[data-active="true"] .rdx-dropdown--list_item:nth-child(2) {
            left: 0;
            top: 70px; }
          .rdx-dropdown--list[data-active="true"] .rdx-dropdown--list_item:nth-child(3) {
            left: 0;
            top: 145px; }
          .rdx-dropdown--list[data-active="true"] .rdx-dropdown--list_item:nth-child(4) {
            left: 0;
            top: 220px; } }
        @media (min-width: 1024px) {
          .rdx-dropdown--list[data-active="true"] .rdx-dropdown--list_item:nth-child(1) {
            left: 0;
            top: 0; }
          .rdx-dropdown--list[data-active="true"] .rdx-dropdown--list_item:nth-child(2) {
            left: 0;
            top: 80px; }
          .rdx-dropdown--list[data-active="true"] .rdx-dropdown--list_item:nth-child(3) {
            left: 0;
            top: 165px; }
          .rdx-dropdown--list[data-active="true"] .rdx-dropdown--list_item:nth-child(4) {
            left: 0;
            top: 255px; } }
        @media (min-width: 1280px) {
          .rdx-dropdown--list[data-active="true"] .rdx-dropdown--list_item:nth-child(1) {
            left: 0;
            top: 0; }
          .rdx-dropdown--list[data-active="true"] .rdx-dropdown--list_item:nth-child(2) {
            left: 0;
            top: 100px; }
          .rdx-dropdown--list[data-active="true"] .rdx-dropdown--list_item:nth-child(3) {
            left: 0;
            top: 200px; }
          .rdx-dropdown--list[data-active="true"] .rdx-dropdown--list_item:nth-child(4) {
            left: 0;
            top: 300px; } }
        @media (min-width: 1920px) {
          .rdx-dropdown--list[data-active="true"] .rdx-dropdown--list_item:nth-child(1) {
            left: 0;
            top: 0; }
          .rdx-dropdown--list[data-active="true"] .rdx-dropdown--list_item:nth-child(2) {
            left: 0;
            top: 125px; }
          .rdx-dropdown--list[data-active="true"] .rdx-dropdown--list_item:nth-child(3) {
            left: 0;
            top: 245px; }
          .rdx-dropdown--list[data-active="true"] .rdx-dropdown--list_item:nth-child(4) {
            left: 0;
            top: 365px; } }
      .rdx-dropdown--list_item {
        color: #fff;
        font-size: 22px;
        margin: 10px 0;
        opacity: 0;
        padding: 0;
        position: absolute;
        text-decoration: none;
        -webkit-transition: left 1s ease-out .5s, opacity .5s ease-out .5s, font-size .35s;
        transition: left 1s ease-out .5s, opacity .5s ease-out .5s, font-size .35s; }
        .rdx-dropdown--list_item:nth-child(1) {
          left: -10%;
          top: 0; }
        .rdx-dropdown--list_item:nth-child(2) {
          left: -15%;
          top: 50px; }
        .rdx-dropdown--list_item:nth-child(3) {
          left: -20%;
          top: 100px; }
        .rdx-dropdown--list_item:nth-child(4) {
          left: -25%;
          top: 150px; }
        @media (min-width: 768px) {
          .rdx-dropdown--list_item {
            margin: 5px 0;
            font-size: 48px; }
            .rdx-dropdown--list_item:nth-child(1) {
              left: -10%;
              top: 0; }
            .rdx-dropdown--list_item:nth-child(2) {
              left: -15%;
              top: 70px; }
            .rdx-dropdown--list_item:nth-child(3) {
              left: -20%;
              top: 145px; }
            .rdx-dropdown--list_item:nth-child(4) {
              left: -25%;
              top: 220px; } }
        @media (min-width: 1024px) {
          .rdx-dropdown--list_item:nth-child(1) {
            left: -10%;
            top: 0; }
          .rdx-dropdown--list_item:nth-child(2) {
            left: -15%;
            top: 80px; }
          .rdx-dropdown--list_item:nth-child(3) {
            left: -20%;
            top: 165px; }
          .rdx-dropdown--list_item:nth-child(4) {
            left: -25%;
            top: 255px; } }
        @media (min-width: 1280px) {
          .rdx-dropdown--list_item {
            margin: 5px 0;
            font-size: 65px; }
            .rdx-dropdown--list_item:nth-child(1) {
              left: -10%;
              top: 0; }
            .rdx-dropdown--list_item:nth-child(2) {
              left: -15%;
              top: 100px; }
            .rdx-dropdown--list_item:nth-child(3) {
              left: -20%;
              top: 200px; }
            .rdx-dropdown--list_item:nth-child(4) {
              left: -25%;
              top: 300px; } }
        @media (min-width: 1920px) {
          .rdx-dropdown--list_item {
            margin: 5px 0;
            font-size: 90px; }
            .rdx-dropdown--list_item:nth-child(1) {
              left: -10%;
              top: 0; }
            .rdx-dropdown--list_item:nth-child(2) {
              left: -15%;
              top: 125px; }
            .rdx-dropdown--list_item:nth-child(3) {
              left: -20%;
              top: 245px; }
            .rdx-dropdown--list_item:nth-child(4) {
              left: -25%;
              top: 365px; } }
        .rdx-dropdown--list_item:hover {
          cursor: pointer;
          color: #fff; }
          .rdx-dropdown--list_item:hover:not(.-cta) {
            font-size: 25px; }
            @media (min-width: 768px) {
              .rdx-dropdown--list_item:hover:not(.-cta) {
                font-size: 52px; } }
            @media (min-width: 1024px) {
              .rdx-dropdown--list_item:hover:not(.-cta) {
                font-size: 70px; } }
            @media (min-width: 1440px) {
              .rdx-dropdown--list_item:hover:not(.-cta) {
                font-size: 70px; } }
            @media (min-width: 1920px) {
              .rdx-dropdown--list_item:hover:not(.-cta) {
                font-size: 100px; } }
        .rdx-dropdown--list_item.-cta {
          bottom: -10%;
          opacity: 0;
          margin: 0;
          -webkit-transition: bottom .75s ease-out 1.5s, opacity .75s ease-out 1s;
          transition: bottom .75s ease-out 1.5s, opacity .75s ease-out 1s;
          -webkit-transition-delay: .5s;
                  transition-delay: .5s;
          width: 125px; }
          @media (min-width: 768px) {
            .rdx-dropdown--list_item.-cta {
              width: 200px; } }
          .rdx-dropdown--list_item.-cta .btn.-line {
            margin: 0; }
    .rdx-dropdown--image {
      background: url(../images/menu-background-performance-experts.jpg) bottom right no-repeat;
      background-size: contain;
      bottom: 0;
      height: 100%;
      opacity: 0;
      position: absolute;
      -webkit-transition: all 1s ease-out .80s;
      transition: all 1s ease-out .80s;
      right: -200px;
      width: 100%;
      z-index: 1; }
      .rdx-dropdown--image[data-active="true"] {
        opacity: 1;
        right: 0; }
  .rdx-menu {
    position: absolute;
    right: 18px;
    top: 18px;
    height: 30px;
    width: 25px;
    z-index: 99; }
    .rdx-menu.-dark .rdx-menu--bar {
      background: #fff; }
    .rdx-menu[data-active="true"] .rdx-menu--bar {
      background: #fff; }
      .rdx-menu[data-active="true"] .rdx-menu--bar:nth-child(1) {
        -webkit-transform: translateY(10px) rotate(45deg);
                transform: translateY(10px) rotate(45deg); }
      .rdx-menu[data-active="true"] .rdx-menu--bar:nth-child(2) {
        opacity: 0; }
      .rdx-menu[data-active="true"] .rdx-menu--bar:nth-child(3) {
        -webkit-transform: translateY(-10px) rotate(-45deg);
                transform: translateY(-10px) rotate(-45deg); }
    @media (min-width: 768px) {
      .rdx-menu {
        height: 30px;
        right: 25px;
        top: 25px;
        width: 40px; } }
    @media (min-width: 1280px) {
      .rdx-menu {
        right: 55px;
        top: 55px; } }
    .rdx-menu:hover {
      cursor: pointer; }
      .rdx-menu:hover:not([data-active="true"]) .rdx-menu--bar:nth-child(1) {
        top: -2px; }
      .rdx-menu:hover:not([data-active="true"]) .rdx-menu--bar:nth-child(3) {
        top: 22px; }
    .rdx-menu--bar {
      background: #fff;
      height: 3px;
      margin: 5px 0;
      position: absolute;
      -webkit-transition: all .25s ease-out;
      transition: all .25s ease-out;
      width: 100%; }
      .rdx-menu--bar:nth-child(1) {
        top: 0; }
      .rdx-menu--bar:nth-child(2) {
        top: 10px; }
      .rdx-menu--bar:nth-child(3) {
        top: 20px; }
  .rdx-content {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    color: #fff;
    height: 100vh;
    left: 50%;
    padding: 25px;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    z-index: 9; }
    .rdx-content--wrapper {
      margin: 100px auto 30px;
      width: 100%; }
      @media (min-width: 768px) {
        .rdx-content--wrapper {
          margin: 150px auto 30px;
          width: 95%; } }
      @media (min-width: 1024px) {
        .rdx-content--wrapper {
          margin: 150px auto 30px;
          width: 75%; } }
      @media (min-width: 1280px) {
        .rdx-content--wrapper {
          margin: 200px auto 30px;
          width: 50%; } }
      @media (min-width: 1920px) {
        .rdx-content--wrapper {
          margin: 280px auto 30px;
          width: 50%; } }
  .rdx-header {
    font-family: ff-basic-gothic-pro, sans-serif;
    font-weight: 700;
    font-style: italic;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    color: #fff;
    font-size: 350px;
    line-height: 1;
    left: 100vw;
    letter-spacing: 0px;
    margin: 0;
    padding: 0;
    position: absolute;
    text-transform: uppercase;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 100vw; }
    .rdx-header--pillar.-fitness, .rdx-header--pillar.-enhancement {
      position: absolute;
      left: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%); }
    .rdx-header--pillar.-enhancement {
      position: absolute;
      left: 850px;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%); }
      @media (min-width: 768px) {
        .rdx-header--pillar.-enhancement {
          left: 1250px; } }
    @media (min-width: 768px) {
      .rdx-header {
        font-size: 550px; } }
  .rdx-subheader {
    font-family: ff-basic-gothic-pro, sans-serif;
    font-weight: 200;
    font-style: italic;
    font-size: 20px;
    line-height: 1.35;
    margin: 0;
    padding: 10px 0; }
    @media (min-width: 400px) {
      .rdx-subheader {
        font-size: 24px; } }
    @media (min-width: 768px) {
      .rdx-subheader {
        font-size: 44px;
        line-height: 1.25;
        padding: 20px 0; } }
    @media (min-width: 1280px) {
      .rdx-subheader {
        font-size: 40px;
        padding: 10px 0 5px; } }
    @media (min-width: 1440px) {
      .rdx-subheader {
        font-size: 48px;
        padding: 20px 0; } }
    @media (min-width: 1920px) {
      .rdx-subheader {
        font-size: 72px;
        line-height: 1.2;
        padding: 15px 0; } }
  .rdx-mission {
    font-family: ff-basic-gothic-pro, sans-serif;
    font-weight: 200;
    font-style: normal;
    font-size: 10px;
    line-height: 1.65;
    margin: 0 auto 20px;
    padding: 0;
    width: 95%; }
    @media (min-width: 400px) {
      .rdx-mission {
        font-size: 12px; } }
    @media (min-width: 768px) {
      .rdx-mission {
        font-size: 18px;
        line-height: 1.55;
        margin: 0 auto;
        width: 80%; } }
    @media (min-width: 1024px) {
      .rdx-mission {
        width: 90%; } }
    @media (min-width: 1280px) {
      .rdx-mission {
        font-size: 16px;
        width: 70%; } }
    @media (min-width: 1920px) {
      .rdx-mission {
        font-size: 20px;
        width: 60%; } }
  .rdx-social {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 30px auto;
    max-width: 135px;
    width: 50%; }
    .rdx-social.-main {
      bottom: 5vh;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      left: 50%;
      margin: 30px auto;
      max-width: 135px;
      position: absolute;
      top: unset;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      width: 50%; }
    @media (min-width: 768px) {
      .rdx-social {
        max-width: 155px; } }
    @media (min-width: 1024px) {
      .rdx-social {
        margin: 0;
        max-width: 135px;
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%); } }
    .rdx-social .-social-media {
      cursor: pointer;
      height: 32px;
      margin: 0 10px;
      width: 32px; }
      @media (min-width: 768px) {
        .rdx-social .-social-media {
          height: 32px;
          width: 32px; } }
      @media (min-width: 1024px) {
        .rdx-social .-social-media {
          height: 25px;
          width: 25px; }
          .rdx-social .-social-media:hover .rdx-social--svg {
            height: 30px;
            width: 30px; } }
    .rdx-social[data-active="true"] .rdx-social--svg {
      fill: #1c1c1c; }
    .rdx-social--svg {
      height: 25px;
      margin: 0;
      width: 25px;
      fill: #fff;
      -webkit-transition: all .25s ease-out;
      transition: all .25s ease-out; }
      @media (min-width: 768px) {
        .rdx-social--svg {
          height: 32px;
          width: 32px; } }
      @media (min-width: 1024px) {
        .rdx-social--svg {
          height: 25px;
          width: 25px; } }
      .rdx-social--svg:nth-child(1) {
        margin-left: 0; }
    @media (min-width: 1024px) {
      .rdx-social {
        width: 100%; } }
  .rdx-overlay {
    background: rgba(0, 5, 35, 0.6);
    height: 100%;
    position: absolute;
    width: 100%; }
    .rdx-overlay[data-active='true'] {
      background: rgba(0, 5, 35, 0.2); }
    .rdx-overlay.-underlay {
      z-index: 0; }
    .rdx-overlay.-approach {
      background: rgba(0, 5, 35, 0.4); }

