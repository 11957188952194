.rdx {
  overflow-x: hidden;

  &-contact--label {
    @include BasicGothicProDemibold;
    font-size: 10px;
    color: $blue;
  }

  &-telephone {
    &--contact {
      @include BasicGothicProLight;

      &.-dark {
        color: $dark;
      }
    }

    &--number {
      @include BasicGothicProDemibold;
      color: $blue;
      cursor: pointer;
      letter-spacing: -1px;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      &.-dark {
        color: $dark;
      }
    }
  }

  &-banner--text {
    @include BasicGothicProBlackItalic;
    color: $light;
    font-size: 40px;
    margin: 0;
    text-transform: uppercase;
    z-index: 9;

    @media (min-width: 400px) {
      font-size: 42px;
    }

    @media (min-width: 768px) {
      font-size: 60px;
    }
    
    @media (min-width: 1024px) {
      font-size: 64px;
      line-height: 1;
    }

    @media (min-width: 1440px) {
      font-size: 90px;
    }
    
    @media (min-width: 1920px) {
      font-size: 130px;
    }
  }
  
  &-banner--subtext {
    @include BasicGothicProBook;
    color: $light;
    display: none;
    font-size: 10px;
    width: 100%;
    z-index: 9;

    @media (min-width: 768px) {
      display: block;
      font-size: 14px;
      line-height: 1.5;
      width: 60%;
    }
    
    @media (min-width: 1440px) {
      font-size: 18px;
      line-height: 1.5;
      width: 60%;
    }
    
    @media (min-width: 1920px) {
      font-size: 18px;
      line-height: 1.55;
    }
    
    @media (min-width: 1920px) {
      font-size: 18px;
      line-height: 1.55;
      width: 42%;
    }
    
    @media (min-width: 2560px) {
      font-size: 18px;
      line-height: 1.55;
      width: 45%;
    }
  }

  &-banner--video {
    overflow: hidden;
    position: relative;
    width: 100vw;

    @media (min-width: 768px) {
      max-height: 430px;
    }
    
    @media (min-width: 768px) {
      max-height: 575px;
    }
    
    @media (min-width: 1440px) {
      max-height: 810px;
    }
    
    @media (min-width: 1920px) {
      max-height: 100vh;
    }
  }

  &-contact--form {
    margin: 20px auto 0;
    width: 90%;

    @media (min-width: 768px) {
      width: 85%;
    }
    
    @media (min-width: 1024px) {
      margin: 100px auto 0;
      width: 95%;
    }
    
    @media (min-width: 1440px) {
      margin: 150px auto 0;
      width: 95%;
    }
    
    @media (min-width: 2560px) {
      margin: 150px 0 0 150px;
      width: 90%;
    }

    .form-elements {
      display: flex;
      flex-direction: column;

      @media (min-width: 1024px) {
        bottom: 200px;
        flex-direction: row;
        margin: auto;
        max-width: 90vw;
      }

      .button-success {
        @include BasicGothicProDemibold;
        background: $dark-base;
        border-radius: 50px;
        border: 0;
        box-shadow: inset 0 0 0 2px $light-base;
        color: $light;
        font-size: 14px;
        margin: 15px auto 30px;
        padding: 15px 50px;
        text-transform: uppercase;
        transition: all .35s ease-out;

        &:hover {
          background: $light-base;
          color: $light;
          cursor: pointer;
        }
        
        @media (min-width: 768px) {
          font-size: 24px;
          margin: 20px auto 30px;
          padding: 20px 80px;
        }
        
        @media (min-width: 1024px) {
          font-size: 18px;
          margin: 15px auto;
          padding: 10px 40px;
        }
        
        @media (min-width: 1440px) {
          margin: 12px auto;
        }
        
        @media (min-width: 1920px) {
          margin: 10px auto;
          padding: 10px 60px;
        }
      }
    }

    .pure-group {
      border: 0;

      &.-input-type {
        margin: 10px 0;

        @media (min-width: 768px) {
          margin: 20px 0;
        }
        
        @media (min-width: 1024px) {
          margin: 0 0;
          padding-left: 10px;
          padding-right: 10px;
          width: 25%;
        }
      }

      &.-input-button {
        margin: 5px 0;

        @media (min-width: 1024px) {
          padding-left: 0;
          padding-right: 7px;
          width: 20%;
        }
      }

      &--label {
        @include BasicGothicProDemibold;
        color: $light;
        display: block;
        font-size: 10px;
        margin-bottom: 10px;
        text-transform: uppercase;

        @media (min-width: 400px) {
          font-size: 14px;
        }
        
        @media (min-width: 1024px) {
          margin-bottom: 0;
        }
      }
      
      &--input {
        @include BasicGothicProDemibold;
        background: transparent;
        border: 0;
        border-bottom: thin solid $light;
        color: $light;
        font-size: 14px;
        outline: 0;
        padding: 10px;
        text-transform: uppercase;
        width: 100%;

        @media (min-width: 1024px) {
          padding: 20px 10px 10px;
        }
        
        @media (min-width: 1280px) {
          font-size: 24px;
          padding: 10px 10px 10px;
        }
        
        @media (min-width: 1920px) {
          padding: 20px 10px 10px;
        }
        
        @media (min-width: 2560px) {
          padding: 20px 10px 10px;
        }
      }

      &--text_call {
        @include BasicGothicProDemibold;
        background: transparent;
        border: 0;
        border: thin solid $light;
        color: $light;
        cursor: pointer;
        font-size: 14px;
        margin: 0 2.5%;
        padding: 10px 0;
        text-transform: uppercase;
        width: 45%;
        float: left;

        &[data-active="true"] {
          border: thin solid $light-base;

          &:focus {
            outline: 0;
          }
        }
        
        @media (min-width: 768px) {
          font-size: 18px;
          padding: 20px 0;
        }

        @media (min-width: 1024px) {
          padding: 18px 0;
          margin: 0 1%;
          width: 48%;
        }
        
        // @media (min-width: 1440px) {
        //   width: 100px;
        // }
        
        // @media (min-width: 1920px) {
        //   width: 150px;
        // }
      }

      &.honeypot-field {
        display: none;
      }
    }
  }
}