.rdx {
  &-banner--full {

    &.-performance {
      background: url('../images/performance-experts-banner-seq-3.jpg') top center no-repeat;
      background-size: cover;
      height: 60vh;

      @media (min-width: 1024px) {
        height: 75vh;
      }
      
      @media (min-width: 1440px) {
        height: 90vh;
      }

      .rdx-banner--full_copy {
        margin: auto;
        padding-left: 0;
        text-align: center;
        z-index: 1;

        @media (min-width: 375px) {
          width: 85%;
        }
        
        @media (min-width: 1024px) {
          width: 60%;
        }
        
        @media (min-width: 1440px) {
          width: 60%;
        }
        
        @media (min-width: 1920px) {
          width: 45%;
        }
      }

      .rdx-banner--text {
        line-height: 1;
        width: 100%;
        
        @media (min-width: 375px) {
          font-size: 38px;
        }
        
        @media (min-width: 400px) {
          font-size: 42px;
        }
        
        @media (min-width: 768px) {
          font-size: 72px;
        }
        
        @media (min-width: 1440px) {
          font-size: 100px;
        }
        
        @media (min-width: 1920px) {
          font-size: 130px;
        }
      }
      
      .rdx-banner--subtext {
        display: block;
        line-height: 1.25;
        width: 85%;

        @media (min-width: 375px) {
          font-size: 12px;
          line-height: 1.5;
        }
        
        @media (min-width: 768px) {
          font-size: 20px;
          line-height: 1.5;
        }

        @media (min-width: 1024px) {
          font-size: 16px;
          margin: 0;
          width: 60%;
        }
        
        @media (min-width: 1920px) {
          font-size: 18px;
          width: 70%;
        }
      }

      @media (min-width: 1920px) {
        height: 100vh;
      }
    }

    &.-functional {
      background: url('../images/functional-training-banner.jpg') top center no-repeat;
      background-size: cover;
      height: 60vh;

      @media (min-width: 1024px) {
        height: 75vh;
      }
      
      @media (min-width: 1440px) {
        height: 90vh;
      }

      .rdx-banner--full_copy {
        margin: auto;
        padding-left: 0;
        text-align: center;
        z-index: 1;

        @media (min-width: 375px) {
          width: 85%;
        }
        
        @media (min-width: 1024px) {
          width: 60%;
        }
        
        @media (min-width: 1440px) {
          width: 65%;
        }
        
        @media (min-width: 1920px) {
          width: 50%;
        }
      }

      .rdx-banner--text {
        line-height: 1;
        width: 100%;
        
        @media (min-width: 375px) {
          font-size: 38px;
        }
        
        @media (min-width: 400px) {
          font-size: 42px;
        }
        
        @media (min-width: 768px) {
          font-size: 72px;
        }
        
        @media (min-width: 1440px) {
          font-size: 100px;
        }
        
        @media (min-width: 1920px) {
          font-size: 130px;
        }
      }
      
      .rdx-banner--subtext {
        display: block;
        line-height: 1.25;
        width: 85%;

        @media (min-width: 375px) {
          font-size: 12px;
          line-height: 1.5;
        }
        
        @media (min-width: 768px) {
          font-size: 20px;
          line-height: 1.5;
        }

        @media (min-width: 1024px) {
          font-size: 16px;
          margin: 0;
          width: 60%;
        }
        
        @media (min-width: 1920px) {
          font-size: 18px;
          width: 70%;
        }
      }

      @media (min-width: 1920px) {
        height: 100vh;
      }
    }
    
    &.-fitness {
      background: url('../images/science-fitness-lab-banner-seq1.jpg') top center no-repeat;
      background-size: cover;
      height: 60vh;

      @media (min-width: 1024px) {
        height: 75vh;
      }
      
      @media (min-width: 1440px) {
        height: 90vh;
      }
      
      @media (min-width: 1920px) {
        height: 100vh;
      }

      .rdx-banner--full_copy {
        margin: auto;
        padding-left: 0;
        text-align: center;
        z-index: 1;

        @media (min-width: 375px) {
          padding: 120px 25px 0;
          width: 85%;
        }
        
        @media (min-width: 375px) 
          and (min-height: 812px) {
          padding: 140px 25px 0;
        }
        
        @media (min-width: 768px) {
          padding: 180px 25px 0;
        }
        
        @media (min-width: 1024px) {
          width: 60%;
        }
        
        @media (min-width: 1280px) {
          padding: 210px 25px 0;
        }
        
        @media (min-width: 1440px) {
          width: 55%;
        }
        
        @media (min-width: 1920px) {
          width: 45%;
        }
      }

      .rdx-banner--text {
        line-height: 1;
        width: 100%;
        
        @media (min-width: 375px) {
          font-size: 38px;
        }
        
        @media (min-width: 400px) {
          font-size: 42px;
        }
        
        @media (min-width: 768px) {
          font-size: 72px;
        }
        
        @media (min-width: 1440px) {
          font-size: 100px;
        }
        
        @media (min-width: 1920px) {
          font-size: 130px;
        }
      }
      
      .rdx-banner--subtext {
        display: block;
        line-height: 1.25;
        width: 85%;

        @media (min-width: 375px) {
          font-size: 12px;
          line-height: 1.5;
        }
        
        @media (min-width: 768px) {
          font-size: 20px;
          line-height: 1.5;
        }

        @media (min-width: 1024px) {
          font-size: 16px;
          margin: 0;
          width: 60%;
        }
        
        @media (min-width: 1920px) {
          font-size: 18px;
          width: 70%;
        }
      }

      @media (min-width: 1920px) {
        height: 100vh;
      }
    }
  }
}