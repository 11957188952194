html {
  scroll-behavior: smooth;
}

.rdx {
  overflow-x: hidden;

  &-telephone {
    font-size: 12px;
    left: 50%;
    position: absolute;
    top: 25px;
    transform: translateX(-50%);
    z-index: 10;

    @media (min-width: 375px) {
      font-size: 14px;
    }

    @media (min-width: 768px) {
      font-size: 18px;
      top: 35px;
    }

    @media (min-width: 1024px) {
      font-size: 16px;
      left: 80%;
      top: 30px;
    }
    
    @media (min-width: 1280px) {
      font-size: 16px;
      left: 80%;
      top: 63px;
    }
    
    @media (min-width: 1440px) {
      font-size: 16px;
      left: 82%;
      top: 63px;
    }
    
    @media (min-width: 1920px) {
      font-size: 16px;
      left: 85%;
    }
    
    @media (min-width: 2560px) {
      font-size: 16px;
      left: 90%;
    }

    &--contact {
      @include BasicGothicProBook;
      color: $light;
      margin-right: 5px;
    }

    &--number {
      @include BasicGothicProBold;
      color: $blue;
      cursor: pointer;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-banner--text {
    @include BasicGothicProBlackItalic;
    color: $light;
    font-size: 34px;
    margin: 0;
    text-transform: uppercase;
    z-index: 9;

    @media (min-width: 768px) {
      font-size: 55px;
    }
    
    @media (min-width: 1024px) {
      font-size: 64px;
      line-height: 1;
    }

    @media (min-width: 1440px) {
      font-size: 90px;
    }
    
    @media (min-width: 1920px) {
      font-size: 135px;
    }
  }
  
  &-banner--subtext {
    @include BasicGothicProBook;
    color: $light;
    display: none;
    font-size: 10px;
    width: 80%;
    z-index: 9;

    &.-thank-you {
      display: block;
    }

    @media (min-width: 1024px) {
      display: block;
      font-size: 14px;
      line-height: 1.5;
      width: 60%;
    }
    
    @media (min-width: 1440px) {
      font-size: 18px;
      line-height: 1.5;
      width: 60%;
    }
    
    @media (min-width: 1920px) {
      font-size: 18px;
      line-height: 1.55;
    }
    
    @media (min-width: 1920px) {
      font-size: 18px;
      line-height: 1.55;
      width: 42%;
    }
    
    @media (min-width: 2560px) {
      font-size: 18px;
      line-height: 1.55;
      width: 45%;
    }
  }

  &-banner--scroll {
    @include BasicGothicProDemibold;
    bottom: 0;
    height: 100px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 120px;
    z-index: 1;

    @media (min-width: 1024px) {
      bottom: 20px;
    }

    &_text {
      color: $light;
      font-size: 10px;
      left: 50%;
      position: absolute;
      top: 15%;
      transform: translate(-50%, -50%);
    }
    
    &_icon {
      fill: $light;
      height: 25px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 25px;
    }
  }

  &-banner--full {
    overflow: hidden;
    position: relative;
    height: 50vh;
    width: 100vw;

    &.rdx-banner--full_copy {
      position: relative;
    }

    @media (min-width: 1024px) {
      height: 100vh;
    }

    &.-approach {
      background: url('../images/method-banner-static-bg.jpg') top center no-repeat;
      background-size: cover;
      height: 70vh;

      @media (min-width: 1024px) {
        height: 74vh;
      }
      
      @media (min-width: 1440px) {
        height: 90vh;
      }
      
      @media (min-width: 1920px) {
        height: 83vh;
      }
      
      @media (min-width: 2560px) {
        height: 100vh;
      }

      .rdx-banner--full_copy {
        left: 50%;
        padding: 0;
        position: absolute;
        text-align: center;
        top: 40%;
        transform: translateX(-50%);
        width: 80%;
        z-index: 1;
        
        @media (min-width: 1024px) {
          width: 70%;
        }
        
        @media (min-width: 1440px) {
          width: 50%;
        }
        
        @media (min-width: 1920px) {
          width: 50%;
        }
        
        @media (min-width: 2560px) {
          width: 50%;
        }
      }
      
      .rdx-banner--subtext {
        display: block;
        font-size: 12px;
        line-height: 1.5;
        width: 100%;
                
        @media (min-width: 768px) {
          font-size: 20px;
          line-height: 1.5;
        }

        @media (min-width: 1024px) {
          font-size: 28px;
          margin: 0;
        }
        
        @media (min-width: 1920px) {
          font-size: 48px;
          // width: 100%;
        }
      }
    }
    
    &.-contact {
      background: $dark-base;
      background: -moz-linear-gradient(bottom, $dark-base 0%, $dark-base 100%);
      background: -webkit-linear-gradient(bottom, $dark-base 0%, $dark-base 100%);
      background: linear-gradient(to top, $dark-base 0%, $dark-base 100%);
      height: auto;

      &.-thank-you {
        height: 40vh;
      }

      @media (min-width: 375px) 
        and (min-height: 812px) {
          height: 80vh;

          &.-thank-you {
            height: 35vh;
          }
      }
     
      @media (min-width: 768px) {
          height: 100vh;

          &.-thank-you {
            height: 35vh;
          }
      }
      
      @media (min-width: 1024px) {
          height: 75vh;

          &.-thank-you {
            height: 50vh;
          }
      }
      
      @media (min-width: 1440px) {
          height: 100vh;

          &.-thank-you {
            height: 60vh;
          }
      }
      
      @media (min-width: 1920px) {

          &.-thank-you {
            height: 70vh;
          }
      }

      .rdx-banner--text {
        color: $light-base;
      }
    }    

    &_copy {
      padding: 80px 0 0 25px;
      position: relative;
      width: 90%;

      &.-thank-you {
        padding: 100px 0 0 25px;
      }

      @media (min-width: 768px) {
        padding: 150px 0 0 70px;
        width: 100%;


        &.-thank-you {
          padding: 150px 0 0 40px;
          width: 80%;
        }
      }

      @media (min-width: 1024px) {
        width: 60%;

        &.-thank-you {
          padding: 200px 0 0 50px;
          width: 80%;
        }
      }
      
      @media (min-width: 1440px) {
        padding: 200px 0 0 70px;
        width: 60%;
      }
      
      @media (min-width: 1920px) {
        padding: 225px 0 0 150px;
        width: 60%;

        &.-thank-you {
          padding: 300px 0 0 150px;
        }
      }
      
      @media (min-width: 2560px) {
        padding: 300px 0 0 150px;
        width: 50%;
      }
    }
  }

  &-banner--video {
    overflow: hidden;
    position: relative;
    width: 100vw;

    @media (min-width: 768px) {
      max-height: 430px;
    }
    
    @media (min-width: 768px) {
      max-height: 575px;
    }
    
    @media (min-width: 1440px) {
      max-height: 810px;
    }
    
    @media (min-width: 1920px) {
      max-height: 100vh;
    }
  }

  &-pillars {
    
    &--heading {
      background: $darkest;
      margin-top: 100px;
      opacity: 0;
      transition: margin-top 1s, opacity 2s;

      &.fade-in {
        margin-top: 0;
        opacity: 1;
      }

      &_wrapper {
        @include BasicGothicProDemibold;
        border: thin solid $darkest;
        outline: thin solid $darkest;
        box-sizing: border-box;
        color: $light;
        font-size: 24px;
        margin: 0 auto;
        padding: 20px 0;
        text-align: center;
        width: 70%;
  
        @media (min-width: 375px) {
          width: 60%;
        }
        
        @media (min-width: 414px) {
          width: 50%;
        }
        
        @media (min-width: 768px) {
          font-size: 34px;
          padding: 30px 0;
          width: 40%;
        }
        
        @media (min-width: 1024px) {
          width: 30%;
        }
        
        @media (min-width: 1280px) {
          width: 25%;
        }
        
        @media (min-width: 1440px) {
          width: 20%;
        }
        
        @media (min-width: 1920px) {
          font-size: 48px;
          width: 21%;
        }
        
        @media (min-width: 2560px) {
          font-size: 48px;
          margin: auto;
          padding: 50px 0;
          width: 18%;
        }
      }
    }

    &--wrapper {
      display: flex;
      justify-content: space-between;
    }

    &--image {
      flex-grow: 1;
      filter: grayscale(100%);
      height: 30vh;
      position: relative;
      transition: all .25s ease-out;
      
      @media (min-width: 1024px) {
        height: 70vh;
      }
      
      &:hover {
        filter: grayscale(0);
        cursor: pointer;
        box-shadow: inset 0 2px $blue;
      }

      &.-balance {
        background: url('../images/method-nav-scroll-balance-seq1.jpg') top center no-repeat;
        background-size: cover;
        margin-top: 100px;
        opacity: 0;
        transition: margin-top 1s, opacity 2s;

        &.fade-in {
          margin-top: 0;
          opacity: 1;
        }
      }
      
      &.-recovery {
        background: url('../images/method-nav-scroll-recovery-seq1.jpg') top center no-repeat;
        background-size: cover;
        margin-top: 100px;
        opacity: 0;
        transition: margin-top 1s .25s, opacity 2s .25s;

        &.fade-in {
          margin-top: 0;
          opacity: 1;
        }
      }
      
      &.-performance {
        background: url('../images/method-nav-scroll-performance-seq1.jpg') top center no-repeat;
        background-size: cover;
        margin-top: 100px;
        opacity: 0;
        transition: margin-top 1s .5s, opacity 2s .5s;
        
        &.fade-in {
          margin-top: 0;
          opacity: 1;
        }
      }
    }

    &--desc {
      @include BasicGothicProBold;
      color: $light;
      font-size: 12px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);

      @media (min-width: 768px) {
        font-size: 20px;
      }
      
      @media (min-width: 1024px) {
        font-size: 24px;
      }
      
      @media (min-width: 1440px) {
        font-size: 28px;
      }
    }
  }

  &-split {
    display: flex;
    justify-content: space-between;
    flex-flow: column;

    &.-reverse {
      @media (min-width: 1024px) {
        flex-direction: row-reverse;
      }
    }

    @media (min-width: 1024px) {
      flex-flow: row;
    }

    &--content {
      flex-grow: 1;

      &_image {
        height: 50vh;
        margin-top: 100px;
        opacity: 0;
        transition: margin-top 1.25s ease-out, opacity 1s ease-out;

        @media (min-width: 375px) {
          height: 50vh;
        }  

        @media (min-width: 1024px) {
          height: 100vh;
          width: 50vw;
        }

        &.fade-in {
          margin-top: 0;
          opacity: 1;
        }
        
        &.fade-delay {
          margin-top: 0;
          opacity: 1;
          transition-delay: 1.25s;
        }

        &.-recovery {
          background: url('../images/recovery-5050-seq1.jpg') top center no-repeat;
          background-size: cover;
        }
        
        &.-balance {
          background: url('../images/method-5050-balance.jpg') top center no-repeat;
          background-size: cover;
        }
        
        &.-performance {
          background: url('../images/method-5050-performance-seq1.jpg') top center no-repeat;
          background-size: cover;
        }
        
        &.-scifit {
          background: url('../images/science-fitness-lab-5050-equipment.jpg') top center no-repeat;
          background-size: cover;
        }
        
        &.-atmosphere {
          background: url('../images/science-fitness-lab-5050-atmosphere.jpg') top center no-repeat;
          background-size: cover;
        }
        
        &.-ivan {
          background: url('../images/image-performance-experts-5050-ivan.jpg') top center no-repeat;
          background-size: cover;
        }
        
        &.-maca {
          background: url('../images/image-performance-experts-5050-maca.jpg') top center no-repeat;
          background-size: cover;
        }
        
        &.-development {
          background: url('../images/functional-training-5050-development.jpg') top center no-repeat;
          background-size: cover;
        }
        
        &.-fitness {
          background: url('../images/functional-training-5050-fitness-wellness.jpg') top center no-repeat;
          background-size: cover;
        }
      }

      &_desc {
        box-sizing: border-box;
        padding: 25px 30px;
        line-height: 1.5;
        position: relative;
        height: 50vh;
        text-align: left;
        margin-top: 100px;
        opacity: 0;
        transition: margin-top 1s .5s, opacity 2s .5s;

        @media (min-width: 375px) {
          height: 50vh;
        }        
       
        @media (min-width: 1024px) {
          height: 100vh;
        }        
        
        &.-light {
          background: $light;
          
          .rdx-split--content_subheading,
          .rdx-split--content_copy {
            color: $darkest;
          }

          .rdx-split--content_heading {
            color: $light-base;
          }

          .btn.-line.-dark {
            color: $darkest;
          }
        }

        &.fade-in {
          margin-top: 0;
          opacity: 1;
        }

        &-inner {
          box-sizing: border-box;
          left: 50%;
          // padding: 0 30px;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 80%;

          @media (min-width: 768px) {
            width: 70%;
          }
          
          @media (min-width: 1024px) {
            width: 80%;
          }
          
          @media (min-width: 1440px) {
            width: 50%;
          }
          
          @media (min-width: 1920px) {
            width: 60%;
          }
        }

        @media (min-width: 768px) {
          padding: 50px 150px;
        }

        @media (min-width: 1024px) {
          height: 100vh;
          padding: 100px 50px;
          width: 50vw;
        }
      }
      
      &_heading {
        @include BasicGothicProBold;
        color: $light;
        font-size: 28px;
        line-height: 1.5;
        margin: 0;
        margin-bottom: 10px;
        padding: 0;
        text-transform: uppercase;

        @media (min-width: 768px) {
          font-size: 42px;
        }
        
        @media (min-width: 1024px) {
          line-height: 1.25;
          font-size: 64px;
        }
      }
      
      &_subheading {
        @include BasicGothicProItalic;
        color: $light;
        font-size: 16px;
        margin: 0;
        padding: 4px 0 8px;

        @media (min-width: 768px) {
          font-size: 20px;
          padding: 10px 0;
        }
      }
      
      &_copy {
        @include BasicGothicProLight;
        color: $light;
        font-size: 10px;
        line-height: 1.5;
        margin: 0;
        padding: 0;

        @media (min-width: 768px) {
          font-size: 15px;
          width: 100%;
        }
        
        @media (min-width: 1440px) {
          font-size: 15px;
          width: 100%;
        }
        
        @media (min-width: 1920px) {
          font-size: 15px;
          width: 50%;
        }
        
        @media (min-width: 2560px) {
          font-size: 15px;
          width: 60%;
        }
      }
    }
  }
  
  &-full {
    box-sizing: border-box;

    &.-reverse {
      @media (min-width: 1024px) {
        .rdx-full--content_image-wrapper {
          left: 10%;
        }
      }
    }

    &--content {
      &_image {
        height: 50vh;
        padding: 85px 40px 100px;
        text-align: center;
        margin-top: 100px;
        opacity: 0;
        transition: margin-top 1s .5s, opacity 2s .5s;

        &.fade-in {
          margin-top: 0;
          opacity: 1;
        }

        &.-recovery {
          background: url('../images/image-method-recovery-background.jpg') top center no-repeat;
          background-size: cover;
        }

        &.-rehab {
          background: url('../images/functional-training-100-rehab.jpg') top center no-repeat;
          background-size: cover;
        }

        @media (min-width: 768px) {
          padding: 180px 150px;
        }
        
        @media (min-width: 1024px) {
          height: 100vh;
          padding: 0;
          position: relative;

          &-wrapper {
            position: absolute;
            right: 10%;
            top: 50%;
            transform: translateY(-50%);
            width: 40%;
            text-align: left;
            margin-top: 100px;
            opacity: 0;
            transition: margin-top 1s .5s, opacity 2s .5s;
            
            &.fade-in {
              margin-top: 0;
              opacity: 1;
            }
          }
        }
        
        @media (min-width: 1440px) {
          height: 100vh;
          padding: 0;
          position: relative;

          &-wrapper {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 40%;
            text-align: left;
            margin-top: 100px;
            opacity: 0;
            transition: margin-top 1s .5s, opacity 2s .5s;
            
            &.fade-in {
              margin-top: 0;
              opacity: 1;
            }
          }
        }
        
        @media (min-width: 1920px) {
          &-wrapper {
            width: 30%;
          }
        }
      }

      &_desc {
        box-sizing: border-box;
        height: 30vh;
        padding: 25px 30px;
        position: relative;
        text-align: center;
      }
      
      &_heading {
        @include BasicGothicProBold;
        color: $light;
        font-size: 28px;
        line-height: 1.5;
        margin: 0;
        text-transform: uppercase;
        padding: 0;

        @media (min-width: 768px) {
          font-size: 64px;
        }
      }
      
      &_subheading {
        @include BasicGothicProItalic;
        color: $light;
        font-size: 16px;
        margin: 0;
        padding: 4px 0 8px;

        @media (min-width: 768px) {
          font-size: 20px;
        }
      }
      
      &_copy {
        @include BasicGothicProLight;
        color: $light;
        font-size: 10px;
        line-height: 1.5;
        margin: 0;
        padding: 0;

        @media (min-width: 768px) {
          font-size: 16px;
          width: 100%;
        }
        
        @media (min-width: 1440px) {
          font-size: 16px;
          width: 75%;
        }
        
        @media (min-width: 1920px) {
          font-size: 16px;
          width: 50%;
        }
      }
    }
  }

  &-action {
    &--strip {
      @include BasicGothicProBold;
      background: $dark-base;
      color: $light;
      box-sizing: border-box;
      padding: 25px 20px;
      text-align: center;
      width: 100vw;
      margin-top: 100px;
      opacity: 0;
      transition: margin-top 1s .5s, opacity 2s .5s;
      
      &.fade-in {
        margin-top: 0;
        opacity: 1;
      }

      @media (min-width: 768px) {
        padding: 50px 150px;

        .btn {
          margin: 25px 0;
        }
      }
      
      @media (min-width: 1024px) {
        padding: 50px 150px;
        min-height: 370px;

        .btn {
          margin: 25px 0 0 0;
        }
      }
      
      @media (min-width: 1440px) {
        padding: 80px 250px;

        .btn {
          margin: 0;
        }
      }
    }

    &--text {
      font-size: 26px;

      @media (min-width: 1024px) {
        font-size: 36px;
        padding: 0 75px;
      }
      
      @media (min-width: 1440px) {
        font-size: 36px;
        padding: 0 140px;
      }
      
      @media (min-width: 1920px) {
        font-size: 36px;
        padding: 0 400px;
      }
      
      @media (min-width: 2560px) {
        font-size: 36px;
        padding: 0 700px;
      }
    }
  }

  &-footer {
    background: $dark-base;
    color: $light;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 40px 25px;
    position: relative;
    margin-top: 100px;
    opacity: 0;
    transition: margin-top 1s .5s, opacity 2s .5s;
    
    &.fade-in {
      margin-top: 0;
      opacity: 1;
    }

    @media (min-width: 1024px) {
      flex-direction: row;
      min-height: 200px;
      padding: 40px 20px;
    }
    
    @media (min-width: 1440px) {
      padding: 40px 120px;
    }
    
    @media (min-width: 1920px) {
      padding: 40px 400px;
    }
    
    @media (min-width: 2560px) {
      padding: 40px 700px;
    }

    &--box {
      flex-grow: 1;
      position: relative;

      &.-logo {
        display: flex;
        flex-direction: column;

        @media (min-width: 1024px) {
          flex-direction: row;
          width: 100px;
        }
      }
      
      &.-info {
        @media (min-width: 1024px) {
          width: 0;

          &.-small {
            padding-left: 40px;
          }
          
          &.-stack {
            padding-left: 15px;
          }
        }
        
        @media (min-width: 1440px) {
          width: 0;

          &.-small {
            padding-left: 40px;
          }
          
          &.-stack {
            padding-left: 40px;
          }
        }
      }

      &.-slogan {
        @media (min-width: 1024px) {
          line-height: 1.3;
          width: 50px;
        }  
      }
      
      &.-social {
        @media (min-width: 1024px) {
          width: 0;
        }  
      }
    }

    &--logo {
      text-align: center;

      .rdx-logo--svg {
        @media (min-width: 1024px) {
          height: 100%;
          width: 100%;
        }
      }

      @media (min-width: 1024px) {
        height: 50px;
        width: 50px;
      }
    }

    &--heading_wrapper {
      position: relative;
      text-transform: uppercase;
      text-align: center;

      @media (min-width: 1024px) {
        text-align: left;
        padding: 5px;
      }
    }

    &--heading {
      @include BasicGothicProBold;
      font-size: 24px;
      margin: 0;
      padding: 0;

      @media (min-width: 768px) {
        font-size: 32px;
      }
      
      @media (min-width: 1024px) {
        font-size: 17px;
      }
    }
    
    &--subheading {
      @include BasicGothicProBoldItalic;
      font-size: 12px;
      margin: 0;
      padding: 5px 25px 15px;
      text-align: center;

      @media (min-width: 768px) {
        font-size: 16px;
      }

      @media (min-width: 1024px) {
        padding: 7px 0;
        text-align: left;
      }
    }

    &--address_outer-wrapper {
      .rdx-footer--address {
        color: $light;
        display: block;
        text-decoration: none;

        &:hover {
          color: $light-base;
        }
      }

      &.-links {
        margin: 15px 0 0 0;
      }

      @media (min-width: 1024px) {
        display: flex;
        flex-direction: column;

        &.-links {
          margin: 0;
        }  
      }
    }

    &--address_wrapper {
      position: relative;
      text-transform: uppercase;
      text-align: center;

      &.-contact {
        @media (min-width: 1024px) {
          margin: 10px 0 0 0;
        }  
      }

      @media (min-width: 1024px) {
        text-align: left;
      }
    }

    &--address {
      @include BasicGothicProLight;
      font-size: 12px;
      margin: 0;
      padding: 10px 0 0 0;

      @media (min-width: 768px) {
        font-size: 14px;
      }

      @media (min-width: 1024px) {
        font-size: 10px;
      }
      
      @media (min-width: 1440px) {
        font-size: 11px;
      }
    }
  }
}