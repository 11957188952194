@import '_fonts';
@import '_brand';
@import '_general';
@import '_base';
@import '_pillars';
@import '_approach';
@import '_contact';
@import '_preloader';
@import '_panoramic';

.rdx {
  background: $dark-base;
  box-sizing: border-box;
  position: absolute;
  height: 100vh;
  overflow: hidden;
  width: 100vw;

  &.-light {
    background: $light;
  }

  &-video {
    background: rgba($darkest, .80);
    display: none;
    height: 100vh;
    left: 0;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: 99999;

    &[data-active="true"] {
      display: block;
    }

    &--exit {
      right: -40px;
      position: absolute;
      top: -40px;
      z-index: 99999;
      color: $light;
      font-size: 48px;
      height: 40px;
      width: 40px;
    }

    &--bar {
      background: $light;
      height: 3px;
      position: absolute;
      top: 50%;
      width: 40px;

      &:nth-child(1) {
        transform: translateY(-50%) rotate(45deg);
      }
      
      &:nth-child(2) {
        transform: translateY(-50%) rotate(-45deg);
      }
    }

    &--overlay {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 60%;
    }
    
    &--player {
      width: 100%;
      height: auto;

      &:focus {
        outline: none;
      }

      &._btn {
        display: none;
      }

      &.-banner {
        display: none;
        left: 0;
        position: absolute;
        top: 0;
        z-index: 0;

        @media (min-width: 1024px) {
          display: block;
        }
      }
    }
  }

  &-logo {
    @include BasicGothicProBold;
    color: $blue;
    left: 8px;
    position: absolute;
    top: 8px;
    transition: all .25s ease-out;
    z-index: 99;

    &:hover {
      cursor: pointer;
    }

    &--svg {
      fill: $blue;
      height: 50px;
      width: 50px;

      @media (min-width: 768px) {
        height: 100px;
        width: 100px;
      }
    }

    &[data-active="true"] {
      .rdx-logo--svg {
        fill: $dark;
      }
    }

    @media (min-width: 1024px) {
      left: 10px;
      top: 10px;
    }
    
    @media (min-width: 1280px) {
      left: 20px;
      top: 25px;
    }
  }

  &-dropdown {
    background: $dark-base;
    background: -moz-linear-gradient(bottom,  $dark-base 0%, $dark-base 100%);
    background: -webkit-linear-gradient(bottom,  $dark-base 0%, $dark-base 100%);
    background: linear-gradient(to bottom,  $dark-base 0%, $dark-base 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$dark-base, endColorstr=$dark-base,GradientType=1);
    display: none;
    height: 100vh;
    left: 0;
    opacity: 0;
    position: absolute;
    transition: all .5s ease-out;
    top: 0;
    width: 100vw;

    &[data-active="true"] {
      opacity: 1;
      z-index: 15;
    }

    &--list {
      @include BasicGothicProBoldItalic;
      margin: 0;
      list-style-type: none;
      left: 50%;
      min-height: 260px;
      padding: 0;
      position: absolute;
      top: 40%;
      transform: translate(-50%, -50%);
      text-transform: uppercase;
      width: 85vw;
      z-index: 5;
      // border: thin solid red;
      
      @media (min-width: 375px)
      and (min-height: 812px) {
        top: 28%;
      }

      @media (min-width: 768px) {
        min-height: 400px;
        top: 40%;
      }
      
      @media (min-width: 1024px) {
        min-height: 430px;
        left: 45%;
        top: 45%;
        width: 65vw;
      }
      
      @media (min-width: 1280px) {
        min-height: 500px;
        left: 50%;
        width: 70vw;
      }
      
      @media (min-width: 1920px) {
        left: 45%;
        min-height: 580px;
      }

      &[data-active="true"] {
        .rdx-dropdown--list_item {
          opacity: 1;

          &.-cta {
            bottom: 0;
            opacity: 1;
            transition: bottom .75s ease-out .80s, 
                        opacity .75s ease-out .80s, 
                        background-color .35s ease-out,
                        color .35s ease-out,
                        box-shadow .35s ease-out;
          }

          &:nth-child(1) {
            left: 0;
            top: 0;
          }
          
          &:nth-child(2) {          
            left: 0;
            top: 50px;
          }
          
          &:nth-child(3) {
            left: 0;
            top: 100px;
          }
          
          &:nth-child(4) {
            left: 0;
            top: 150px;
          }
          
          @media (min-width: 768px) {
            &:nth-child(1) {
              left: 0;
              top: 0;
            }
            
            &:nth-child(2) {          
              left: 0;
              top: 70px;
            }
            
            &:nth-child(3) {
              left: 0;
              top: 145px;
            }
            
            &:nth-child(4) {
              left: 0;
              top: 220px;
            }
          }

          @media (min-width: 1024px) {
            &:nth-child(1) {
              left: 0;
              top: 0;
            }
            
            &:nth-child(2) {          
              left: 0;
              top: 80px;
            }
            
            &:nth-child(3) {
              left: 0;
              top: 165px;
            }
            
            &:nth-child(4) {
              left: 0;
              top: 255px;
            }
          }
          
          @media (min-width: 1280px) {
            &:nth-child(1) {
              left: 0;
              top: 0;
            }
            
            &:nth-child(2) {          
              left: 0;
              top: 100px;
            }
            
            &:nth-child(3) {
              left: 0;
              top: 200px;
            }
            
            &:nth-child(4) {
              left: 0;
              top: 300px;
            }
          }
          
          @media (min-width: 1920px) {
            &:nth-child(1) {
              left: 0;
              top: 0;
            }
            
            &:nth-child(2) {          
              left: 0;
              top: 125px;
            }
            
            &:nth-child(3) {
              left: 0;
              top: 245px;
            }
            
            &:nth-child(4) {
              left: 0;
              top: 365px;
            }
          }
        }
      }

      &_item {
        color: $light;
        font-size: 22px;
        margin: 10px 0;
        opacity: 0;
        padding: 0;
        position: absolute;
        text-decoration: none;
        transition: left 1s ease-out .5s, opacity .5s ease-out .5s, font-size .35s;

        &:nth-child(1) {
          left: -10%;
          top: 0;
        }
        &:nth-child(2) {
          left: -15%;
          top: 50px;
        }
        &:nth-child(3) {
          left: -20%;
          top: 100px;
        }
        &:nth-child(4) {
          left: -25%;
          top: 150px;
        }

        @media (min-width: 768px) {
          margin: 5px 0;
          font-size: 48px;

          &:nth-child(1) {
            left: -10%;
            top: 0;
          }
          
          &:nth-child(2) {          
            left: -15%;
            top: 70px;
          }
          
          &:nth-child(3) {
            left: -20%;
            top: 145px;
          }
          
          &:nth-child(4) {
            left: -25%;
            top: 220px;
          }
        }

        @media (min-width: 1024px) {
          &:nth-child(1) {
            left: -10%;
            top: 0;
          }
          
          &:nth-child(2) {          
            left: -15%;
            top: 80px;
          }
          
          &:nth-child(3) {
            left: -20%;
            top: 165px;
          }
          
          &:nth-child(4) {
            left: -25%;
            top: 255px;
          }
        }

        @media (min-width: 1280px) {
          margin: 5px 0;
          font-size: 65px;

          &:nth-child(1) {
            left: -10%;
            top: 0;
          }
          
          &:nth-child(2) {          
            left: -15%;
            top: 100px;
          }
          
          &:nth-child(3) {
            left: -20%;
            top: 200px;
          }
          
          &:nth-child(4) {
            left: -25%;
            top: 300px;
          }
        }
        
        @media (min-width: 1920px) {
          margin: 5px 0;
          font-size: 90px;

          &:nth-child(1) {
            left: -10%;
            top: 0;
          }
          
          &:nth-child(2) {
            left: -15%;
            top: 125px;
          }
          
          &:nth-child(3) {
            left: -20%;
            top: 245px;
          }
          
          &:nth-child(4) {
            left: -25%;
            top: 365px;
          }
        }

        &:hover {
          cursor: pointer;
          color: $light;

          &:not(.-cta) {
            font-size: 25px;

            @media (min-width: 768px) {
              font-size: 52px;
            }
            
            @media (min-width: 1024px) {
              font-size: 70px;
            }

            @media (min-width: 1440px) {
              font-size: 70px;
            }
            @media (min-width: 1920px) {
              font-size: 100px;
            }
          }
        }

        &.-cta {
          bottom: -10%;
          opacity: 0;
          margin: 0;
          transition: bottom .75s ease-out 1.5s, opacity .75s ease-out 1s;
          transition-delay: .5s;
          width: 125px;

          @media (min-width: 768px) {
            width: 200px;
          }

          .btn.-line {
            margin: 0;
          }
        }
      }
    }

    &--image {
      background: url('../images/menu-background-performance-experts.jpg') bottom right no-repeat;
      background-size: contain;
      bottom: 0;
      height: 100%;
      opacity: 0;
      position: absolute;
      transition: all 1s ease-out .80s;
      right: -200px;
      width: 100%;
      z-index: 1;

      &[data-active="true"] {
        opacity: 1;
        right: 0;
      }
    }
  }

  &-menu {
    position: absolute;
    right: 18px;
    top: 18px;
    height: 30px;
    width: 25px;
    z-index: 99;

    &.-dark {
      .rdx-menu--bar {
        background: $light;
      }
    }

    &[data-active="true"] {
      .rdx-menu--bar {
        background: $light;

        &:nth-child(1) {
          transform: translateY(10px) rotate(45deg);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:nth-child(3) {
          transform: translateY(-10px) rotate(-45deg);
        }
      }
    }

    @media (min-width: 768px) {
      height: 30px;
      right: 25px;
      top: 25px;
      width: 40px;
    }
    
    @media (min-width: 1280px) {
      right: 55px;
      top: 55px;
    }

    &:hover {
      cursor: pointer;

      &:not([data-active="true"]) {
        .rdx-menu--bar {
          &:nth-child(1) {
            top: -2px;
          }
          
          &:nth-child(3) {
            top: 22px;
          }
        } 
      } 
    }

    &--bar {
      background: $light;
      height: 3px;
      margin: 5px 0;
      position: absolute;
      transition: all .25s ease-out;
      width: 100%;

      &:nth-child(1) {
        top: 0;
      }
      &:nth-child(2) {
        top: 10px;
      }
      &:nth-child(3) {
        top: 20px;
      }
    }
  }

  &-content {
    box-sizing: border-box;
    // border: thin solid red;
    color: $light;
    height: 100vh;
    left: 50%;
    padding: 25px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    z-index: 9;

    &--wrapper {
      margin: 100px auto 30px;
      width: 100%;
      
      @media (min-width: 768px) {
        margin: 150px auto 30px;
        width: 95%;
      }
      
      @media (min-width: 1024px) {
        margin: 150px auto 30px;
        width: 75%;
      }
      
      @media (min-width: 1280px) {
        margin: 200px auto 30px;
        width: 50%;
      }
      
      @media (min-width: 1920px) {
        margin: 280px auto 30px;
        width: 50%;
      }
    }
  }

  &-header {
    @include BasicGothicProBoldItalic;
    box-sizing: border-box;
    color: $light;
    font-size: 350px;
    line-height: 1;
    left: 100vw;
    letter-spacing: 0px;
    margin: 0;
    padding: 0;
    position: absolute;
    text-transform: uppercase;
    top: 50%;
    transform: translateY(-50%);
    width: 100vw;

    &--pillar {
      &.-fitness,
      &.-enhancement {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      &.-enhancement {
        position: absolute;
        left: 850px;
        top: 50%;
        transform: translateY(-50%);

        @media (min-width: 768px) {
          left: 1250px;
        }
      }
    }

    @media (min-width: 768px) {
      font-size: 550px;
    }
  }

  &-subheader {
    @include BasicGothicProLightItalic;
    font-size: 20px;
    line-height: 1.35;
    margin: 0;
    padding: 10px 0;

    @media (min-width: 400px) {
      font-size: 24px; 
    }

    @media (min-width: 768px) {
      font-size: 44px;
      line-height: 1.25;
      padding: 20px 0;
    }

    @media (min-width: 1280px) {
      font-size: 40px;
      padding: 10px 0 5px;
    }
    
    @media (min-width: 1440px) {
      font-size: 48px;
      padding: 20px 0;
    }
    
    @media (min-width: 1920px) {
      font-size: 72px;
      line-height: 1.2;
      padding: 15px 0;
    }
  }
  
  &-mission {
    @include BasicGothicProLight;
    font-size: 10px;
    line-height: 1.65;
    margin: 0 auto 20px;
    padding: 0;
    width: 95%;

    @media (min-width: 400px) {
      font-size: 12px;
    }

    @media (min-width: 768px) {
      font-size: 18px;
      line-height: 1.55;
      margin: 0 auto;
      width: 80%;
    }
    
    @media (min-width: 1024px) {
      width: 90%;
    }
    
    @media (min-width: 1280px) {
      font-size: 16px;
      width: 70%;
    }

    @media (min-width: 1920px) {
      font-size: 20px;
      width: 60%;
    }
  }
  
  &-social {
    display: flex;
    margin: 30px auto;
    max-width: 135px;
    width: 50%;

    &.-main {
      bottom: 5vh;
      display: flex;
      left: 50%;
      margin: 30px auto;
      max-width: 135px;
      position: absolute;
      top: unset;
      transform: translateX(-50%);
      width: 50%;
    }

    @media (min-width: 768px) {
      max-width: 155px;
    }
    
    @media (min-width: 1024px) {
      margin: 0;
      max-width: 135px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    .-social-media {
      cursor: pointer;
      height: 32px;
      margin: 0 10px;
      width: 32px;

      @media (min-width: 768px) {
        height: 32px;
        width: 32px;
      }
      
      @media (min-width: 1024px) {
        height: 25px;
        width: 25px;

        &:hover {
          .rdx-social--svg {
            height: 30px;
            width: 30px;
          }
        }
      }
    }

    &[data-active="true"] {
      .rdx-social--svg {
        fill: $dark;
      }
    }

    &--svg {
      height: 25px;
      margin: 0;
      width: 25px;
      fill: $light;
      transition: all .25s ease-out;

      @media (min-width: 768px) {
        height: 32px;
        width: 32px;
      }
      
      @media (min-width: 1024px) {
        height: 25px;
        width: 25px;
      }

      &:nth-child(1) {
        margin-left: 0;
      }
    }

    @media (min-width: 1024px) {
      width: 100%;
    }
  }

  &-overlay {
    background: rgba($dark-base, .60);
    height: 100%;
    position: absolute;
    width: 100%;

    &[data-active='true'] {
      background: rgba($dark-base, .20);
    }

    &.-underlay {
      z-index: 0;
    }

    &.-approach {
      background: rgba($dark-base, .40);
    }
  }
}