.rdx {
  &-panoramic {
    opacity: 0;

    &[data-active='true'] {
      opacity: 1;
    }

    &--bumper {
      &[data-dir='prev'],
      &[data-dir='next'] {
        cursor: pointer;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        width: 25px;

        .rdx-icon--arrow {
          fill: $light;
        }

        @media (min-width: 1024px) {
          font-size: 70px;
        }
      }

      &[data-dir='prev'] {
        left: 20px;
        
        @media (min-width: 1024px) {
          left: 50px;
        }
      }
      
      &[data-dir='next'] {
        right: 20px;

        @media (min-width: 1024px) {
          right: 50px;
        }
      }
    }
  }

  &-orbs {
    left: 0;
    position: absolute;
    top: 0;

    &--point {
      background: rgba($light-base, .25);
      border-radius: 100%;
      height: 55px;
      width: 55px;

      @media (min-width: 768px) {
        height: 75px;
        width: 75px;
      }

      &:hover {
        cursor: pointer;

        &:before {
          border: 5px solid rgba($light-base, 1);
          height: 20px;
          width: 20px;

          @media (min-width: 768px) {
            height: 30px;
            width: 30px;
          }
        }
      }

      &:before {
        border: 2px solid rgba($light-base, 1);
        border-radius: 100%;
        content: '';
        height: 15px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: all .35s ease-out;
        width: 15px;

        @media (min-width: 768px) {
          height: 25px;
          width: 25px;
        }
      }

      &[data-detail='pillars'] {
        height: 5px;
        left: 10vw;
        opacity: 0;
        position: absolute;
        top: 20vh;
        transition: all .45s .25s ease-out;
        transform-origin: center;
        width: 5px;

        &[data-active='true'] {
          opacity: 1;
          height: 55px;
          width: 55px;
        }

        @media (min-width: 768px) {
          left: 30vw;
          top: 15vh;

          &[data-active='true'] {
            opacity: 1;
            height: 75px;
            width: 75px;
          }
        }
      }
      
      &[data-detail='balance'] {
        height: 5px;
        left: 60vw;
        opacity: 0;
        position: absolute;
        top: 60vh;
        transition: all .45s .25s ease-out;
        transform-origin: center;
        width: 5px;

        &[data-active='true'] {
          opacity: 1;
          height: 55px;
          width: 55px;
        }

        @media (min-width: 768px) {
          left: 60vw;
          top: 55vh;

          &[data-active='true'] {
            opacity: 1;
            height: 75px;
            width: 75px;
          }
        }
      }
      
      &[data-detail='experts'] {
        height: 5px;
        left: 35vw;
        opacity: 0;
        position: absolute;
        top: 35vh;
        transition: all .45s .25s ease-out;
        transform-origin: center;
        width: 5px;
        
        &[data-active='true'] {
          opacity: 1;
          height: 55px;
          width: 55px;
        }

        @media (min-width: 768px) {
          left: 30vw;
          top: 30vh;

          &[data-active='true'] {
            opacity: 1;
            height: 75px;
            width: 75px;
          }
        }
      }
      
      &[data-detail='equipment'] {
        height: 5px;
        left: 75vw;
        opacity: 0;
        position: absolute;
        top: 55vh;
        transition: all .45s .25s ease-out;
        transform-origin: center;
        width: 5px;

        &[data-active='true'] {
          opacity: 1;
          height: 55px;
          width: 55px;
        }

        @media (min-width: 768px) {
          left: 70vw;
          top: 50vh;

          &[data-active='true'] {
            opacity: 1;
            height: 75px;
            width: 75px;
          }
        }
      }
      
      &[data-detail='performance'] {
        height: 5px;
        left: 10vw;
        opacity: 0;
        position: absolute;
        top: 20vh;
        transition: all .45s .25s ease-out;
        transform-origin: center;
        width: 5px;

        &[data-active='true'] {
          opacity: 1;
          height: 55px;
          width: 55px;
        }

        @media (min-width: 768px) {
          left: 25vw;
          top: 55vh;

          &[data-active='true'] {
            opacity: 1;
            height: 75px;
            width: 75px;
          }
        }
      }
      
      &[data-detail='approach'] {
        height: 5px;
        left: 40vw;
        opacity: 0;
        position: absolute;
        top: 45vh;
        transition: all .45s .25s ease-out;
        transform-origin: center;
        width: 5px;

        &[data-active='true'] {
          opacity: 1;
          height: 55px;
          width: 55px;
        }

        @media (min-width: 768px) {
          left: 45vw;
          top: 45vh;

          &[data-active='true'] {
            opacity: 1;
            height: 75px;
            width: 75px;
          }
        }
      }
      
      &[data-detail='recovery'] {
        height: 5px;
        left: 10vw;
        opacity: 0;
        position: absolute;
        top: 55vh;
        transition: all .45s .25s ease-out;
        transform-origin: center;
        width: 5px;

        &[data-active='true'] {
          opacity: 1;
          height: 55px;
          width: 55px;
        }

        @media (min-width: 768px) {
          left: 35vw;
          top: 50vh;

          &[data-active='true'] {
            opacity: 1;
            height: 75px;
            width: 75px;
          }
        }
        
        @media (min-width: 1280px) {
          left: 25vw;
          top: 50vh;

          &[data-active='true'] {
            opacity: 1;
            height: 75px;
            width: 75px;
          }
        }
      }
      
      &[data-detail='level'] {
        height: 5px;
        left: 60vw;
        opacity: 0;
        position: absolute;
        top: 25vh;
        transition: all .45s .25s ease-out;
        transform-origin: center;
        width: 5px;

        &[data-active='true'] {
          opacity: 1;
          height: 55px;
          width: 55px;
        }

        @media (min-width: 768px) {
          left: 60vw;
          top: 20vh;

          &[data-active='true'] {
            opacity: 1;
            height: 75px;
            width: 75px;
          }
        }
        
        @media (min-width: 1280px) {
          left: 60vw;
          top: 25vh;

          &[data-active='true'] {
            opacity: 1;
            height: 75px;
            width: 75px;
          }
        }
      }
    }
  }

  &-modal {
    background: $dark-base;
    display: none;
    height: 95%;
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all .45s ease-out;
    width: 95%;
    z-index: 100;

    @media (min-width: 375px) 
    and (min-height: 812px) {
      height: 75%;
    }

    @media (min-width: 768px) {
      .-cta.btn {
        margin: 50px 0;
      }
    }

    @media (min-width: 1024px) {
      height: 70%;
      width: 80%;
    }
    
    @media (min-width: 2560px) {
      width: 70%;
    }

    &[data-active='true'] {
      opacity: 1;
    }

    &--exit {
      height: 30px;
      position: absolute;
      right: 30px;
      top: 30px;
      width: 30px;
      z-index: 1;

      &_svg {
        height: 100%;
        width: 100%;
      }
    }

    &--content {
      display: flex;
      flex-direction: column;
      height: 100%;

      @media (min-width: 1024px) {
        flex-direction: row;
      }

      &_image {
        background: url('../images/overview-tour-1a-modal-balance.jpg') top center no-repeat;
        background-size: cover;
        height: 70%;
        width: 100%;

        @media (min-width: 1024px) {
          height: 100%;
          width: 50%;
        }
      }
      
      &_copy {
        color: $light;
        height: 100%;
        position: relative;
        width: 100%;

        &-inner {
          width: 90%;

          @media (min-width: 1024px) {
            width: 65%;
          }
          
          @media (min-width: 1920px) {
            width: 75%;
          }
        }

        @media (min-width: 1024px) {
          width: 50%;
        }

        &-header {
          @include BasicGothicProBold;
          font-size: 30px;
          margin: 0 0 5px 0;
          padding: 0;

          @media (min-width: 768px) {
            font-size: 38px;
          }

          @media (min-width: 1024px) {
            font-size: 42px;
            margin: 0 0 15px 0;
          }
        }
        
        &-text {
          @include BasicGothicProBook;
          font-size: 12px;
          line-height: 1.5;
          max-height: 130px;
          min-height: 100px;
          margin: 0;
          overflow-y: auto;
          padding: 0;

          @media (min-width: 768px) {
            font-size: 16px;
          }
          
          @media (min-width: 1024px) {
            max-height: 180px;
          }
        }

        &-inner {
          left: 50%;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}